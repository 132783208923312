import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../Components/Forms/SubmitBtn";
import SSL from "../../Components/SSL";
import { Currencies } from "@ra/utils-v2/dist/gateway";
import DepositFormHidden from "./DepositFormHidden";
import { callEupaymentzPostDeposit } from "../../API/API";
import { getError } from "../../utils/index";

interface IDepositForm {
  currency: number;
  amount: number;
  id: string;
}

const DepositForm = ({ amount, currency, id }: IDepositForm) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    method: "",
  });

  const [goodToSubmit, setGoodToSubmit] = useState(false);
  const [hiddenData, setHiddenData] = useState({});
  const [status, setStatus] = useState<
    "success" | "loading" | "error" | undefined
  >();
  const [error, setError] = useState({ message: "", retry: false });

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      setStatus("loading");
      const data = { paymentChannel: state.method };
      const res = await callEupaymentzPostDeposit(id, data);
      setHiddenData(res.data);
      setGoodToSubmit(true);
      setStatus("success");
    } catch (err) {
      setError(getError(err));
      setStatus("error");

      if (getError(err).retry) {
        setTimeout(() => {
          setStatus(undefined);
        }, 2000);
      }
    }
  };

  const submitText = () => {
    let text = t("pay-submit-button", {
      amount,
      currency: Currencies[currency],
    });
    return text;
  };

  const handleChange = (event: any) => {
    const { value } = event.target;

    setState({ method: value });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {error.message && (
          <div className="alert alert-danger" role="alert">
            {error.message}
          </div>
        )}

        <div className="mt-3 mb-3">
          <label htmlFor="method">{t("select-payment-method")}:</label>
          <select
            value={state.method}
            className="form-control"
            name="method"
            id="method"
            onChange={handleChange}
            required
          >
            <option value="">{t("select")}</option>
            <option value="USD">{t("china-union-pay-option-label")}</option>
            <option value="HKD">{t("wechat-pay-option-label")}</option>
          </select>
        </div>

        <div>
          <Button text={submitText()} type="submit" status={status} />
          <SSL />
        </div>
      </form>

      <DepositFormHidden hiddenData={hiddenData} submit={goodToSubmit} />
    </>
  );
};

export default DepositForm;
