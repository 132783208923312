import { PayzipDepositPayload } from "@ra/utils-v2";
import Axios from "axios";
import { applyAuthInterceptor } from "./authInterceptor";
import axiosMocker from "./axiosMocker";
import {
  ICryptoBtcWithdraw,
  IDragAndPayWithdrawFormData,
  IEcardACHPostDeposit,
  IEcardPostDeposit,
  INetellerWithdraw,
  IOlePayCheckIfAccountExists,
  IOlePayDepositStep2,
  IOlePayRegSendEmailCode,
  IOlePayRegSendMobileCode,
  IOlePayRegVerifyEmailCode,
  IOlePayRegVerifyMobileCode,
  IOlePayRegistration,
  IOlePayVerifyExistingAccount,
  IOlePayWithdraw,
  IOlePayWithdrawStep2,
  IPayRedeemDeposit,
  IPayRedeemWithdraw,
  IUPayDepositStep1,
  IUPayDepositStep2,
  IUPayWithdraw,
  IUPayWithdrawStep2,
  IcallEupaymentzGlobalPostDeposit,
  IcallEupaymentzPostDeposit,
  IcallQuickcardPostDeposit,
  OlePayDeposit,
} from "./types";

interface Iconfig {
  baseURL?: string;
  headers?: any;
  timeout?: number;
}

// config axios
let axiosConfig: Iconfig = {
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
};

export const externalAxios = Axios.create(axiosConfig);
export const myAxios = Axios.create({
  ...axiosConfig,
  ...(import.meta.env.DEV
    ? { headers: { "h-origin": import.meta.env.REACT_APP_GWFE_URL } }
    : {}),
});
applyAuthInterceptor(myAxios);

// configure mocks
if (import.meta.env.REACT_APP_USE_AXIOS_MOCK === "true") {
  axiosMocker(myAxios);
  axiosMocker(externalAxios);
}

// Add a request interceptor
myAxios.interceptors.request.use(
  (request) => request,
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
myAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

// api calls
export const callPymtType = (id: string, mode: string) =>
  myAxios.get(`/${mode}/${id}`);

export const callOlePayWithdraw = (data: IOlePayWithdraw, id: string) =>
  myAxios.post(`/withdraw/${id}`, data);

export const callOlePayDeposit = (data: OlePayDeposit, id: string) =>
  myAxios.post(`/deposit/${id}`, data);

export const callDragAndPayDeposit = (id: string) =>
  myAxios.post(`/deposit/${id}`);

export const callDragAndPayWithdraw = (
  id: string,
  data: IDragAndPayWithdrawFormData
) => myAxios.post(`/withdraw/${id}`, data);

export const callNetellerDeposit = (id: string, email: string) =>
  myAxios.post(`/deposit/${id}`, { customerEmail: email });

export const callNetellerDepositFinish = (id: string) =>
  myAxios.post(`/deposit/finish/${id}`);

export const callUPayWithdraw = (data: IUPayWithdraw, id: string) =>
  myAxios.post(`/withdraw/${id}`, data);

export const callUPayDepositStep2 = (data: IUPayDepositStep2, id: string) =>
  myAxios.post(`/deposit/finish/${id}`, data);

export const callOlePayDepositStep2 = (data: IOlePayDepositStep2, id: string) =>
  myAxios.post(`/deposit/finish/${id}`, data);

export const callOlePayWithdrawStep2 = (
  data: IOlePayWithdrawStep2,

  id: string
) => myAxios.post(`/withdraw/admin/confirm/${id}`, data);

export const callUPayWithdrawStep2 = (data: IUPayWithdrawStep2, id: string) =>
  myAxios.post(`/withdraw/admin/confirm/${id}`, data);

export const callPayRedeemDeposit = (data: IPayRedeemDeposit, id: string) =>
  myAxios.post(`/deposit/${id}`, data);

export const callPayRedeemWithdraw = (data: IPayRedeemWithdraw, id: string) =>
  myAxios.post(`/withdraw/${id}`, data);

export const callCryptoBtcWithdraw = (data: ICryptoBtcWithdraw, id: string) =>
  myAxios.post(`/withdraw/${id}`, data);
export const callNetellerWithdraw = (data: INetellerWithdraw, id: string) =>
  myAxios.post(`/withdraw/${id}`, data);

export const callUPayDepositStep1 = (data: IUPayDepositStep1, id: string) =>
  myAxios.post(`/deposit/${id}`, data);

export const callOlePayRegSendMobileCode = (data: IOlePayRegSendMobileCode) => {
  return myAxios.post(`/olepayAccount/sendVerificationCodeMobile`, data);
};

export const callOlePayRegSendEmailCode = (data: IOlePayRegSendEmailCode) => {
  return myAxios.post(`/olepayAccount/sendVerificationCodeEmail`, data);
};

export const callOlePayRegVerifyMobileCode = (
  data: IOlePayRegVerifyMobileCode
) => {
  return myAxios.post(`/olepayAccount/verifyMobileCode`, data);
};

export const callOlePayRegVerifyEmailCode = (
  data: IOlePayRegVerifyEmailCode
) => {
  return myAxios.post(`/olepayAccount/verifyEmailCode`, data);
};

export const callOlePayRegistration = (data: IOlePayRegistration) => {
  return myAxios.post(`/olepayAccount/createUser`, data);
};

export const callOlePayCheckIfAccountExists = (
  data: IOlePayCheckIfAccountExists
) => {
  return myAxios.post(`/olepayAccount/checkIfExist`, data);
};

export const callOlePayVerifyExistingAccount = (
  data: IOlePayVerifyExistingAccount
) => {
  return myAxios.post(`/olepayAccount/verifyAccount`, data);
};

export const callCryptoBtcPostDeposit = (id: string, data: { kind: string }) =>
  myAxios.post(`/deposit/${id}`, data);

export const callCryptoBtcGetDeposit = (id: string) =>
  myAxios.get(`/deposit/${id}`);

export const callCryptoBtcCheckInvoice = (id: string) =>
  myAxios.get(`/btcInvoice/${id}`);

export const callEupaymentzPostDeposit = (
  id: string,
  data: IcallEupaymentzPostDeposit
) => myAxios.post(`/deposit/${id}`, data);

export const callEupaymentzGlobalPostDeposit = (
  id: string,
  data: IcallEupaymentzGlobalPostDeposit
) => myAxios.post(`/deposit/${id}`, data);

export const callQuickcardPostDeposit = (
  id: string,
  data: IcallQuickcardPostDeposit
) => myAxios.post(`/deposit/${id}`, data);

export const callGreenBoxPostDeposit = (id: string) =>
  myAxios.post(`/deposit/${id}`, {});

export const callEcardPostDeposit = (id: string, data: IEcardPostDeposit) =>
  myAxios.post(`/deposit/${id}`, data);

export const callPayzipDeposit = (id: string, data: PayzipDepositPayload) =>
  myAxios.post(`/deposit/${id}`, data);

export const callEcardACHPostDeposit = (
  id: string,
  data: IEcardACHPostDeposit
) => {
  let formData = new FormData();

  formData.append("firstBs", data.firstBs);
  if (data.secondBs) {
    formData.append("secondBs", data.secondBs);
  }
  formData.append("abaNumber", data.abaNumber);
  formData.append("accountNumber", data.accountNumber);
  formData.append("bankName", data.bankName);

  return myAxios.post(`/deposit/echeck/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// txID is required on the BE to detect brand
export const callGetSettings = (txID: string) =>
  myAxios.get("/settings/all/public", { params: { id: txID } });
