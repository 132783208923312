import React, { FC, useEffect, useState } from "react";
import { callPymtType } from "../API/API";

import PayRedeemDepositForm from "../Forms/PayRedeem/PayRedeemDepositForm";
import UPayDepositForm from "../Forms/UPay/UPayDepositForm";
import UPayWithdrawForm from "../Forms/UPay/UPayWithdrawForm";
import UPayWithdrawStep2Form from "../Forms/UPay/UPayWithdrawStep2Form";
import OlePayDepositForm from "../Forms/OlePay/OlePayDepositForm";
import OlePayWithdrawForm from "../Forms/OlePay/OlePayWithdrawForm";
import NetellerDepositForm from "../Forms/Neteller/NetellerDepositForm";
import PayRedeemWithdrawForm from "../Forms/PayRedeem/PayRedeemWithdrawForm";
import CryptoBtcDeposit from "../Forms/CryptoBtc/CryptoBtcDeposit";
import CryptoBtcWithdrawForm from "../Forms/CryptoBtc/CryptoBtcWithdrawForm";
import NetellerWithdrawForm from "../Forms/Neteller/NetellerWithdrawForm";
import OlePayWithdrawStep2Form from "../Forms/OlePay/OlePayWithdrawStep2Form";
import EupaymentzDeposit from "../Forms/Eupaymentz/EupaymentzDeposit";
import EupaymentzGlobalDeposit from "../Forms/EupaymentzGlobal/EupaymentzGlobalDeposit";
import QuickcardDeposit from "../Forms/Quickcard/QuickcardDeposit";
import GreenBoxDeposit from "../Forms/GreenBox/GreenBoxDeposit";
import EcheckDeposit from "../Forms/Echeck/EcheckDeposit";
import PayzipDeposit from "../Forms/Payzip/PayzipDeposit";
import EcheckACHDeposit from "../Forms/Echeck/EcheckACHDeposit";
import { DragAndPayDeposit, DragAndPayWithdraw } from "../Forms/DragAndPay";
import Zeply from "../Forms/Zeply";

import {
  ProviderTypes,
  ProvidersID,
  Providers,
} from "@ra/utils-v2/dist/gateway";
import { RouteComponentProps } from "react-router-dom";
import Instacoins from "Forms/Instacoins";
import ErrorBox from "../Components/ErrorBox";
import { getError, useTxId } from "../utils/index";
import CardWrap from "../Components/CardWrap";
import { useTranslation } from "react-i18next";
import { RenderComponentByStatus } from "../utils/RenderComponentByStatus";

interface IProps extends RouteComponentProps {
  mode: string;
  admin?: boolean;
}

interface IpymtInfo {
  status: string;
  type: string;
  currency: number;
  amount: number;
  meta: any;
  redirect: string;
  cryptoCurrencies: string[];
  message?: string;
  cardTypes?: [] | { id: string; name: string }[];
  providerSpecificMetaData?: any;
}

const TransactionPage: FC<IProps> = (props) => {
  const { admin, mode } = props;

  const { t } = useTranslation();
  const txID = useTxId();

  const defaultError = txID
    ? { message: "", retry: false }
    : { message: t("invalid-transaction-id"), retry: false };

  const [error, setError] = useState(defaultError);

  const [pymtInfo, setPymtInfo] = useState<Partial<IpymtInfo>>({});
  const [status, setStatus] = useState("loading");

  useEffect(() => {
    callPymtType(txID, mode)
      .then((res) => {
        setPymtInfo(res.data);
        setStatus("success");
      })
      .catch((err) => {
        setError(getError(err));
        setStatus("error");
      });
  }, [txID, mode]);

  const renderTxSwitch = () => {
    const {
      type = "",
      currency = 0,
      amount = 0,
      meta,
      redirect = "",
      cryptoCurrencies = [],
      status: pymtStatus = "",
      cardTypes = [],
    } = pymtInfo;
    const pymtType = ProvidersID[type];
    const compType = ProviderTypes[pymtType];

    if (props.mode === "deposit") {
      switch (compType) {
        case "CUSTOM":
          switch (type) {
            case Providers.D_P:
              return (
                <CardWrap mode={props.mode}>
                  <DragAndPayDeposit
                    id={txID}
                    status={pymtStatus}
                    metaRedirect={
                      meta && meta.initResponse && meta.initResponse.Url
                    }
                    automaticRedirectURL={pymtInfo.redirect}
                    errMessage={pymtInfo.message}
                  />
                </CardWrap>
              );
            case Providers.ECHECK_ACH:
              return (
                <CardWrap mode={props.mode}>
                  <EcheckACHDeposit
                    id={txID}
                    status={pymtStatus}
                    errMessage={pymtInfo.message}
                    currency={currency}
                    amount={amount}
                  />
                </CardWrap>
              );
            case Providers.ECHECK:
              return (
                <EcheckDeposit
                  id={txID}
                  status={pymtStatus}
                  errMessage={pymtInfo.message}
                  currency={currency}
                  amount={amount}
                />
              );
            case Providers.PAYZIP:
              return (
                <PayzipDeposit
                  id={txID}
                  status={pymtStatus}
                  errMessage={pymtInfo.message}
                  currency={currency}
                  amount={amount}
                />
              );
            case Providers.QUICKCARD:
              return (
                <QuickcardDeposit
                  id={txID}
                  status={pymtStatus}
                  errMessage={pymtInfo.message}
                  currency={currency}
                  amount={amount}
                />
              );
            case Providers.GREENBOX:
              return (
                <GreenBoxDeposit
                  id={txID}
                  status={pymtStatus}
                  errMessage={pymtInfo.message}
                />
              );
            case Providers.EUPAYMENTZ:
              return (
                <EupaymentzDeposit
                  id={txID}
                  status={pymtStatus}
                  errMessage={pymtInfo.message}
                  currency={currency}
                  amount={amount}
                />
              );
            case Providers.EUPAYMENTZ_GLOBAL:
              return (
                <EupaymentzGlobalDeposit
                  id={txID}
                  status={pymtStatus}
                  errMessage={pymtInfo.message}
                  currency={currency}
                  amount={amount}
                  cardTypes={cardTypes}
                />
              );
            case Providers.PAYREDEEM:
              return (
                <CardWrap mode={props.mode}>
                  <PayRedeemDepositForm
                    id={txID}
                    currency={currency}
                    amount={amount}
                  />
                </CardWrap>
              );
            case Providers.NETELLER:
              return (
                <CardWrap mode={props.mode}>
                  <NetellerDepositForm id={txID} status={pymtStatus} />
                </CardWrap>
              );
            case Providers.UPAYCARD:
              return (
                <CardWrap mode={props.mode}>
                  <UPayDepositForm
                    id={txID}
                    currency={currency}
                    amount={amount}
                  />
                </CardWrap>
              );
            case Providers.OLEPAY:
              return (
                <CardWrap mode={props.mode}>
                  <OlePayDepositForm
                    id={txID}
                    currency={currency}
                    amount={amount}
                  />
                </CardWrap>
              );
            case Providers.INSTACOINS:
              return (
                <CardWrap mode={props.mode} classes="col">
                  <Instacoins />
                </CardWrap>
              );
            case Providers.INSTACOINS_WIRE:
              return (
                <CardWrap mode={props.mode} classes="col">
                  <Instacoins />
                </CardWrap>
              );
            case Providers.INSTACOINS_ZELLE:
              return (
                <CardWrap mode={props.mode} classes="col">
                  <Instacoins />
                </CardWrap>
              );
            case Providers.Z_CARD:
              return (
                <CardWrap
                  mode={props.mode}
                  classes="col"
                  childrenWrapperClasses="card shadow mb-4 overflow-hidden"
                >
                  <Zeply
                    currency={currency}
                    amount={amount}
                    meta={meta}
                    redirect={redirect}
                    mode="deposit"
                    variant="card"
                    txID={txID}
                  />
                </CardWrap>
              );
            default:
              return (
                <ErrorBox text={t("unknown-payment-type")} mode="deposit" />
              );
          }
        case "BTC":
          switch (type) {
            case Providers.CRYPTO_BTC:
              return (
                <CardWrap mode={props.mode}>
                  <CryptoBtcDeposit
                    cryptoCurrencies={cryptoCurrencies}
                    redirectUrl={redirect}
                    id={txID}
                    status={pymtStatus}
                  />
                </CardWrap>
              );
            default:
              return (
                <ErrorBox text={t("unknown-payment-type")} mode="deposit" />
              );
          }
        default:
          return <ErrorBox text={t("unknown-payment-type")} mode="deposit" />;
      }
    } else if (props.mode === "withdraw" && admin) {
      switch (compType) {
        case "CUSTOM":
          switch (type) {
            case Providers.OLEPAY:
              return (
                <CardWrap mode="admin/withdrawals">
                  <OlePayWithdrawStep2Form
                    id={txID}
                    currency={currency}
                    amount={amount}
                  />
                </CardWrap>
              );
            case Providers.UPAYCARD:
              return (
                <CardWrap mode="admin/withdrawals">
                  <UPayWithdrawStep2Form
                    id={txID}
                    currency={currency}
                    amount={amount}
                    token={meta && meta.tokenNumber}
                  />
                </CardWrap>
              );
            default:
              return (
                <ErrorBox
                  text={t("unknown-payment-type")}
                  mode="admin/withdrawals"
                />
              );
          }
        default:
          return (
            <ErrorBox
              text={t("unknown-payment-type")}
              mode="admin/withdrawals"
            />
          );
      }
    } else if (props.mode === "withdraw") {
      switch (compType) {
        case "CUSTOM":
          switch (type) {
            case Providers.D_P:
              return (
                <CardWrap mode={props.mode}>
                  <DragAndPayWithdraw
                    id={txID}
                    currency={currency}
                    amount={amount}
                    paymentChannels={
                      pymtInfo &&
                      pymtInfo.providerSpecificMetaData &&
                      pymtInfo.providerSpecificMetaData.paymentChannels
                    }
                  />
                </CardWrap>
              );
            case Providers.NETELLER:
              return (
                <CardWrap mode={props.mode}>
                  <NetellerWithdrawForm
                    id={txID}
                    currency={currency}
                    amount={amount}
                  />
                </CardWrap>
              );
            case Providers.PAYREDEEM:
              return (
                <CardWrap mode={props.mode}>
                  <PayRedeemWithdrawForm
                    id={txID}
                    currency={currency}
                    amount={amount}
                    isPayRedeemV2={
                      pymtInfo &&
                      pymtInfo.providerSpecificMetaData &&
                      pymtInfo.providerSpecificMetaData.isPayRedeemV2
                    }
                  />
                </CardWrap>
              );
            case Providers.OLEPAY:
              return (
                <CardWrap mode={props.mode}>
                  <OlePayWithdrawForm
                    id={txID}
                    currency={currency}
                    amount={amount}
                  />
                </CardWrap>
              );
            case Providers.UPAYCARD:
              return (
                <CardWrap mode={props.mode}>
                  <UPayWithdrawForm
                    id={txID}
                    currency={currency}
                    amount={amount}
                  />
                </CardWrap>
              );
            default:
              return (
                <ErrorBox text={t("unknown-payment-type")} mode="withdraw" />
              );
          }
        case "BTC":
          switch (type) {
            case Providers.CRYPTO_BTC:
              return (
                <CardWrap mode={props.mode}>
                  <CryptoBtcWithdrawForm
                    id={txID}
                    currency={currency}
                    amount={amount}
                    cryptoKind={meta.cryptoKind}
                  />
                </CardWrap>
              );
            default:
              return (
                <ErrorBox text={t("unknown-payment-type")} mode="withdraw" />
              );
          }
        default:
          return <ErrorBox text={t("unknown-payment-type")} mode="withdraw" />;
      }
    } else {
      return <ErrorBox text={t("unknown-payment-type")} />;
    }
  };

  return RenderComponentByStatus(status, error, props.mode, renderTxSwitch);
};

export default TransactionPage;
