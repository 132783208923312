import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { FeatureFlagsContext } from '../../featureFlagsContext.esm.js';

const THIRTY_SECONDS = 1000 * 30;
const CRMAdapter = ({ axiosInstance, txId, }) => {
    const fetcher = ({ queryKey: [_, txId], }) => {
        return axiosInstance
            .get("/settings/all/public", {
            params: { id: txId },
        })
            .then((res) => res.data.data);
    };
    const useFeatureFlag = (featureFlagName) => {
        const { data: settings = [], isLoading, error, } = useQuery(["feature-flags", txId], fetcher, {
            staleTime: THIRTY_SECONDS,
        });
        if (isLoading) {
            return { isLoading, enabled: false };
        }
        if (error) {
            return { isLoading, enabled: false, error };
        }
        const featureFlag = settings.find((setting) => setting.name === featureFlagName);
        const couldNotFindFeatureFlag = !featureFlag;
        if (process.env.NODE_ENV === "development") {
            if (couldNotFindFeatureFlag) {
                console.error(`RA adapter wasn't able to find the feature flag from backend response, make sure you've added the the feature flag to the database. FEATURE FLAG NAME: ${featureFlagName}`);
            }
        }
        return couldNotFindFeatureFlag
            ? {
                enabled: false,
                error: new Error("Couldn't find the feature flag from backend response"),
                isLoading: false,
            }
            : {
                enabled: featureFlag.data.enabled,
                isLoading: false,
            };
    };
    const FeatureFlagProvider = (props) => {
        const queryClient = useQueryClient();
        React.useEffect(() => {
            queryClient.prefetchQuery(["feature-flags", txId], fetcher);
        }, [queryClient]);
        return (React.createElement(FeatureFlagsContext.Provider, { value: useFeatureFlag }, props === null || props === void 0 ? void 0 : props.children));
    };
    FeatureFlagProvider.displayName = "FeatureFlagProvider(RA)";
    return { FeatureFlagProvider, useFeatureFlag };
};

export { CRMAdapter };
