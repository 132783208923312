import { createContext, useContext } from 'react';

const contextDefaultValue = () => {
    return { enabled: false, isLoading: true };
};
const FeatureFlagsContext = createContext(contextDefaultValue);
const useFeatureFlag = (featureFlagName) => {
    const useFeatureFlag = useContext(FeatureFlagsContext);
    return useFeatureFlag(featureFlagName);
};

export { FeatureFlagsContext, contextDefaultValue, useFeatureFlag };
