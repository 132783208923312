import React, { FC } from "react";
import { useBrand } from "context/Brand";
import { LogglyTracker } from "loggly-jslogger";

export const LoggerProvider: FC = ({ children }) => {
  const { configs } = useBrand();

  const logger = new LogglyTracker();

  if (configs?.enableLoggly) {
    logger.push({
      logglyKey: configs.logglyToken,
      sendConsoleErrors: true,
    });
  }

  return <>{children}</>;
};
