import { useQuery } from "react-query";
import { useTxId } from "utils";
import { myAxios } from "../API";

const ONE_MINUTE = 60 * 1000;

const usePublicSettingsQuery = () => {
  const txId = useTxId();
  return useQuery(
    ["settings-public", txId],
    () =>
      myAxios
        .get("/settings/all/public", {
          params: { id: txId },
        })
        .then((res) => res.data),
    {
      staleTime: ONE_MINUTE,
    }
  );
};

export { usePublicSettingsQuery };
