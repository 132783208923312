import React from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  logo?: any;
}

const SSL = ({ logo }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex align-items-center justify-content-center mt-3">
      {logo}
      <div className="font-weight-bold text-success">
        <i className="fas fa-lock" /> <small>{t("SSL-label")}</small>
      </div>
    </div>
  );
};

export default SSL;
