import * as Sentry from "@sentry/react";
import { Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import Branding from "./Components/Branding";
import TransactionPage from "./Pages/TransactionPage";
import ErrorBox from "./Components/ErrorBox";
import { PrivateRoute } from "Components/PrivateRoute";
import SentryRoute from "Components/SentryRoute";
import { useRBACLogoutHandler } from "utils";
import "./i18n";
import { useBrand } from "context/Brand";

let Container = styled.div`
  font-family: ${(props) => props.theme.font};
  background: ${(props) => props.theme.background || "#efeef0"};
  min-height: 100vh;
`;

const Routes = () => {
  const { t } = useTranslation();
  useRBACLogoutHandler();

  return (
    <Switch>
      <SentryRoute
        exact
        path="/pay"
        render={(props) => <TransactionPage {...props} mode="deposit" />}
      />
      <SentryRoute
        exact
        path="/withdraw/admin"
        render={(props) => <TransactionPage {...props} mode="withdraw" admin />}
      />
      <PrivateRoute
        exact
        path="/withdraw"
        render={(props) => <TransactionPage {...props} mode="withdraw" />}
      />
      <SentryRoute
        render={() => (
          <ErrorBox
            title={t("not-found-page-title")}
            text={t("not-found-page-message")}
          />
        )}
      />
    </Switch>
  );
};

const App = () => {
  const { t } = useTranslation();
  const { configs } = useBrand();

  return (
    <Container className="App">
      <Helmet>
        <title>
          {t("app-meta-title", { brandName: configs?.websiteName })}
        </title>
      </Helmet>

      <div className="container">
        <Branding />

        <div className="row justify-content-center">
          <Routes />
        </div>
      </div>
    </Container>
  );
};

export default Sentry.withProfiler(App);
