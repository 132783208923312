import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

let InputContainer = styled.div`
  position: relative;

  label {
    width: 100%;
    border: ${(props) => props.theme.input.border};
    padding: 11px;
    padding-left: 18px;
    border-radius: ${(props) => props.theme.input.borderRadius};
    font-size: 16px;
    color: ${(props) => props.theme.input.color};
    cursor: pointer;

    &:focus {
      border: ${(props) => props.theme.input.focusBorder};
      box-shadow: 0px 0px 5px 6px rgba(0, 0, 0, 0.05) !important;
    }

    &::placeholder {
      opacity: 0.4;
    }
  }

  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`;

let Label = styled.label`
  font-size: 15px;
  opacity: 0.7;
  width: 100%;
`;

// extend interface is an option
export interface Iprops {
  label?: string;
  id?: string;
  className?: any;
  placeholder?: string;
  required?: boolean;
  labelClasses?: string;
  value?: HTMLInputElement;
  errors?: any;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Input = (props: Iprops) => {
  const { t } = useTranslation();
  const { label, labelClasses, required, value, onChange } = props;
  const labelStyles: any = {};

  let inputStyle: any = {};
  // handle error message if there is one...
  const errors = props.errors || false;
  if (errors) {
    inputStyle.borderColor = "red";
    labelStyles.color = "red";
  }
  return (
    <div className="form-group mb-3 position-relative">
      {label && (
        <Label className={labelClasses} htmlFor={props.id} style={labelStyles}>
          {label}
        </Label>
      )}
      <InputContainer>
        <label>
          {value ? value.name : t("file-upload-default-label")}
          <input
            required={required}
            type="file"
            onChange={onChange}
            style={inputStyle}
          />
        </label>
      </InputContainer>
      {errors && <small style={{ color: "red" }}>{errors[0]}</small>}
    </div>
  );
};

export default Input;
