import { useBrand } from "context/Brand";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Brand = styled.img`
  max-height: 60px;
  max-width: 100%;
`;

const Branding = () => {
  const { t } = useTranslation();
  const { configs } = useBrand();
  const isDev = import.meta.env.DEV;
  const baseURL =
    configs?.bucketURL || !isDev
      ? `${configs?.bucketURL}/gw`
      : `${import.meta.env.PUBLIC_URL}/brand/assets`;

  return (
    <div className="text-center pt-5 mb-5">
      <Brand src={`${baseURL}/brand-logo.png`} alt={t("branding-log-alt")} />
    </div>
  );
};

export default Branding;
