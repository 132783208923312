import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../Components/Forms/SubmitBtn";
import Cards from "react-credit-cards";
import SSL from "../../Components/SSL";
import { Currencies } from "@ra/utils-v2/dist/gateway";
import { format } from "@buttercup/react-formatted-input";
import DepositFormHidden from "./DepositFormHidden";
import { callEupaymentzGlobalPostDeposit } from "../../API/API";
import { getError } from "../../utils/index";

const cardNoFormat = [
  { char: /\d/, repeat: 4 },
  { exactly: " " },
  { char: /\d/, repeat: 4 },
  { exactly: " " },
  { char: /\d/, repeat: 4 },
  { exactly: " " },
  { char: /\d/, repeat: 4 },
];

const cvcFormat = [{ char: /\d/, repeat: 3 }];

const expFormat = [
  { char: /\d/, repeat: 2 },
  { exactly: "/" },
  { char: /\d/, repeat: 4 },
];

interface IDepositForm {
  currency: number;
  amount: number;
  id: string;
  cardTypes: { id: string; name: string }[];
}

const DepositForm = ({ amount, currency, id, cardTypes }: IDepositForm) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    method: "",
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    firstname: "",
    lastname: "",
    number: "",
    issuer: "",
    raw: {
      cvc: "",
      expiryM: "",
      expiryY: "",
      number: "",
    },
  });

  const [goodToSubmit, setGoodToSubmit] = useState(false);
  const [hiddenData, setHiddenData] = useState({});
  const [status, setStatus] = useState<
    "success" | "loading" | "error" | undefined
  >();
  const [error, setError] = useState({ message: "", retry: false });

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const { expiryM, expiryY, number } = state.raw;
    const { cvc, method } = state;

    try {
      setStatus("loading");
      const data = {
        cardNumber: number,
        cardMonth: expiryM,
        cardYear: expiryY,
        cardCvv: cvc,
        cardType: method,
      };

      const res = await callEupaymentzGlobalPostDeposit(id, data);
      setHiddenData(res.data);
      setGoodToSubmit(true);
      setStatus("success");
    } catch (err) {
      setError(getError(err));
      setStatus("error");

      if (getError(err).retry) {
        setTimeout(() => {
          setStatus(undefined);
        }, 2000);
      }
    }
  };

  const submitText = () => {
    let text = t("pay-submit-button", {
      amount,
      currency: Currencies[currency],
    });
    return text;
  };

  const handleChange = (event: any) => {
    const { value, name } = event.target;

    if (name === "number") {
      const { formatted, raw } = format(value, cardNoFormat);
      setState({
        ...state,
        number: formatted,
        raw: {
          ...state.raw,
          number: raw,
        },
      });
    }

    if (name === "cvc") {
      const { formatted, raw } = format(value, cvcFormat);
      setState({
        ...state,
        cvc: formatted,
        raw: {
          ...state.raw,
          cvc: raw,
        },
      });
    }

    if (name === "expiry") {
      const { formatted } = format(value, expFormat);
      const splitDate = formatted.split("/");

      setState({
        ...state,
        expiry: formatted,
        raw: {
          ...state.raw,
          expiryM: splitDate[0],
          expiryY: splitDate[1],
        },
      });
    }

    if (name === "firstname") {
      setState({
        ...state,
        firstname: value,
        name: `${value} ${state.lastname}`,
      });
    }

    if (name === "lastname") {
      setState({
        ...state,
        name: `${state.firstname} ${value}`,
        lastname: value,
      });
    }

    if (name === "method") {
      setState({
        ...state,
        method: value,
      });
    }
  };

  const handleFocus = (e: any) => {
    setState({ ...state, focus: e.target.name });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {error.message && (
          <div className="alert alert-danger" role="alert">
            {error.message}
          </div>
        )}

        <div className="mt-3 mb-3">
          <label htmlFor="method">{t("select-payment-method")}:</label>
          <select
            value={state.method}
            className="form-control"
            name="method"
            id="method"
            onChange={handleChange}
            required
          >
            <option value="">{t("select")}</option>
            {cardTypes &&
              cardTypes.length > 0 &&
              cardTypes.map((cardType) => (
                <option key={cardType.id} value={cardType.id}>
                  {cardType.name}
                </option>
              ))}
          </select>
        </div>

        <Cards
          cvc={state.cvc}
          expiry={state.expiry}
          // @ts-ignore
          focused={state.focus}
          name={state.name}
          number={state.number}
          callback={({ issuer }) => {
            setState({
              ...state,
              issuer,
            });
          }}
        />

        <div className="mt-3 mb-3">
          <div className="form-row">
            <div className="col">
              <input
                className="form-control mb-2"
                type="text"
                name="firstname"
                value={state.firstname}
                onChange={handleChange}
                placeholder={t("first-name-input-placeholder")}
                onFocus={handleFocus}
                required
              />
            </div>
            <div className="col">
              <input
                className="form-control mb-2"
                type="text"
                name="lastname"
                value={state.lastname}
                onChange={handleChange}
                placeholder={t("last-name-input-placeholder")}
                onFocus={handleFocus}
                required
              />
            </div>
          </div>
          <input
            className="form-control mb-2"
            type="text"
            name="number"
            value={state.number}
            onChange={handleChange}
            placeholder={t("card-number-input-placeholder")}
            onFocus={handleFocus}
            required
          />
          <div className="d-flex">
            <input
              className="form-control mr-1"
              type="text"
              name="expiry"
              value={state.expiry}
              onChange={handleChange}
              placeholder="mm/yyyy"
              onFocus={handleFocus}
              required
            />
            <input
              className="form-control ml-1"
              type="text"
              name="cvc"
              value={state.cvc}
              onChange={handleChange}
              placeholder="CVC"
              onFocus={handleFocus}
              required
            />
          </div>
        </div>

        <div>
          <Button text={submitText()} type="submit" status={status} />
          <SSL />
        </div>
      </form>

      <DepositFormHidden hiddenData={hiddenData} submit={goodToSubmit} />
    </>
  );
};

export default DepositForm;
