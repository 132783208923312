import React, { useState, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import Input from "../../Components/Forms/Input";
import { Helmet } from "react-helmet";
import {
  callOlePayDeposit,
  callOlePayCheckIfAccountExists,
} from "../../API/API";
import SubmitBtn from "../../Components/Forms/SubmitBtn";
import { useFormState } from "react-use-form-state";
import OlePayLogo from "../../assets/olepay-logo.png";
import OlePayDepositStep2Form from "./OlePayDepositStep2Form";
import OlePayRegisterForm from "./OlePayRegisterForm";
import { getError } from "../../utils/index";
import SSL from "../../Components/SSL";
import { useBrand } from "context/Brand";

export interface IProps {
  id: string;
  currency: number;
  amount: number;
}

const OlePayDepositForm = (props: IProps) => {
  const { t } = useTranslation();
  const { configs } = useBrand();
  const [formState, { email }] = useFormState();
  const [status, setStatus] = useState("");
  const [accStatus, setAccStatus] = useState();
  const [step, setStep] = useState(1);
  const [error, setError] = useState({ retry: false, message: "" });
  const { id, amount, currency } = props;
  const [customerPass, setCustomerPass] = useState("");
  const {
    values: { customerEmail },
  } = formState;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setError({ retry: false, message: "" });

    try {
      setStatus("loading"); // form state loading

      // check if Ole user exists
      const checkOleAccount = await callOlePayCheckIfAccountExists({
        txId: id,
        email: customerEmail,
      });
      setAccStatus(checkOleAccount.data.status);

      if (checkOleAccount.data.status === "EXIST_IN_COMPANY") {
        await callOlePayDeposit({ customerEmail }, id);
      }

      // tell user we need another email...
      if (checkOleAccount.data.status === "EXIST_IN_OTHER_COMPANY") {
        setError({
          message: t("olepay-duplicate-email-register-error"),
          retry: true,
        });
        setStatus("error");

        setTimeout(() => {
          setStatus("");
        }, 1500);

        return;
      }

      setStatus("success");

      // wanna see success message before progressing
      setTimeout(() => {
        setStatus(""); // reset
        if (checkOleAccount.data.status === "EXIST_IN_COMPANY") {
          // send straight to step 3
          setStep(4);
        } else {
          // send to step 2, to either register or verify acc forms
          setStep(2);
        }
      }, 1500);
    } catch (err) {
      setError(getError(err));
      setStatus("error");
      if (getError(err).retry) {
        setTimeout(() => {
          setStatus("");
        }, 2000);
      }
    }
  };

  const registrationSuccessCallback = async (password: string) => {
    setCustomerPass(password);
    setStep(3); // ask them to fund their ole account
  };

  const handleConfirmedTopUp = async () => {
    setStatus("loading");
    try {
      await callOlePayDeposit(
        { customerEmail: formState.values.customerEmail },
        id
      );
      setStatus("success");
      setTimeout(() => {
        setStatus("");
        setStep(4); // now go to step 3
      }, 1500);
    } catch (err) {
      setError(getError(err));
      setStatus("error");
      if (getError(err).retry) {
        setTimeout(() => {
          setStatus("");
        }, 2000);
      }
    }
  };

  return (
    <div>
      {error.message && (
        <div className="alert alert-danger" role="alert">
          {error.message}
        </div>
      )}

      {step === 1 && (
        <form onSubmit={handleSubmit}>
          <Helmet>
            <title>
              {t("olepay-deposit-page-meta-title", {
                brandName: configs?.websiteName,
              })}
            </title>
          </Helmet>

          <Input
            className="form-control"
            placeholder={t("email-input-placeholder")}
            icon="fas fa-user"
            label={t("olepay-email-input-label")}
            required
            {...email("customerEmail")}
          />

          <SubmitBtn status={status} />
        </form>
      )}

      {step === 2 && accStatus === "NOT_EXIST" && (
        <OlePayRegisterForm
          customerEmail={formState.values.customerEmail}
          successCallback={registrationSuccessCallback}
          txId={id}
        />
      )}

      {step === 3 && (
        <div className="text-center">
          <h4>{t("olepay-successful-registration")}</h4>
          <p>{t("olepay-login-instructions")}</p>
          <p className="card p-4 bg-secondary text-white">
            {t("username-short")}: {customerEmail} <br />
            {t("password-short")}: {customerPass}
          </p>
          <p>
            <a
              rel="noopener noreferrer nofollow"
              target="_blank"
              href="https://oleplatform.com/login/"
            >
              <i className="fas fa-external-link-alt mr-1" />
              {t("click-here-to-add-funds")}
            </a>
          </p>
          <p>{t("olepay-continue-after-adding-funds")}</p>
          <SubmitBtn
            type="button"
            text={t("i-have-added-funds")}
            onClick={handleConfirmedTopUp}
            status={status}
          />
        </div>
      )}

      {step === 4 && (
        <OlePayDepositStep2Form id={id} currency={currency} amount={amount} />
      )}

      <SSL
        logo={
          <img
            src={OlePayLogo}
            alt={t("olepay-logo-alt")}
            className="mr-3"
            style={{ height: "40px" }}
          />
        }
      />
    </div>
  );
};

export default OlePayDepositForm;
