import React, { useState, FormEvent } from "react";
import Input from "../../Components/Forms/Input";
import { Helmet } from "react-helmet";
import { callOlePayDepositStep2 } from "../../API/API";
import SubmitBtn from "../../Components/Forms/SubmitBtn";
import { useFormState } from "react-use-form-state";
import { getError } from "../../utils/index";
import { Currencies } from "@ra/utils-v2/dist/gateway";
import { useTranslation } from "react-i18next";
import { useBrand } from "context/Brand";
export interface IProps {
  id: string;
  currency: number;
  amount: number;
}

const OlePayDepositStep2Form = (props: IProps) => {
  const { t } = useTranslation();
  const { configs } = useBrand();
  const [formState, { text }] = useFormState();
  const [status, setStatus] = useState("");
  const [error, setError] = useState({ retry: false, message: "" });
  const { id, amount, currency } = props;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setError({ retry: false, message: "" });

    const {
      values: { code },
    } = formState;

    const data = {
      code,
    };

    try {
      setStatus("loading");
      const pymtresppnse = await callOlePayDepositStep2(data, id);
      setStatus("success");

      // // redirect
      if (import.meta.env.PROD) {
        // wanna see success message before redirect, so wait a sec
        setTimeout(() => {
          window.location.href = pymtresppnse.data.redirect;
        }, 1000);
      }
    } catch (err) {
      setError(getError(err));
      setStatus("error");

      if (getError(err).retry) {
        setTimeout(() => {
          setStatus("");
        }, 2000);
      }
    }
  };

  const submitText = () => {
    let text = t("deposit-submit-button", {
      amount,
      currency: Currencies[currency],
    });
    return text;
  };

  return (
    <form onSubmit={handleSubmit}>
      <Helmet>
        <title>
          {t("olepay-deposit-confirmation-page-meta-title", {
            brandName: configs?.websiteName,
          })}
        </title>
      </Helmet>

      {error.message && (
        <div className="alert alert-danger" role="alert">
          {error.message}
        </div>
      )}

      <Input
        className="form-control"
        placeholder="00000"
        icon="fas fa-key"
        label={t("olepay-confirmation-code-input-label")}
        required
        {...text("code")}
      />

      <SubmitBtn status={status} text={submitText()} />
    </form>
  );
};

export default OlePayDepositStep2Form;
