import React, { useState, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import Input from "../../Components/Forms/Input";
import FileUpload from "../../Components/Forms/FileUpload";
import { useFormState } from "react-use-form-state";
import SubmitBtn from "../../Components/Forms/SubmitBtn";
import { Currencies } from "@ra/utils-v2/dist/gateway";
import { callEcardACHPostDeposit } from "../../API/API";
import { getError } from "../../utils/index";
import SSL from "../../Components/SSL";
import { useBrand } from "context/Brand";

interface IProps {
  id: string;
  status: string;
  errMessage?: string;
  currency: number;
  amount: number;
}

const EcheckACHDeposit = ({
  status,
  amount,
  currency,
  id,
  errMessage,
}: IProps) => {
  const { t } = useTranslation();
  const { configs } = useBrand();
  const [formState, { text }] = useFormState({
    firstBs: "",
    secondBs: "",
  });
  const [submitStatus, setSubmitStatus] = useState("");
  const [error, setError] = useState({ message: "", retry: false });
  const [firstFile, setFirstFile] = useState(undefined);
  const [secondFile, setSecondFile] = useState(undefined);
  const submitText = () => {
    let text = t("deposit-submit-button", {
      amount,
      currency: Currencies[currency],
    });
    return text;
  };

  const selectFirstFile = (event: { target: { files: any } }) => {
    setFirstFile(event.target.files[0]);
  };

  const selectSecondFile = (event: { target: { files: any } }) => {
    setSecondFile(event.target.files[0]);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setError({ message: "", retry: false });

    const {
      values: { abaNumber, accountNumber, bankName },
    } = formState;

    const data = {
      abaNumber,
      accountNumber,
      bankName,
      firstBs: firstFile as unknown as Blob,
      secondBs: secondFile,
    };

    try {
      setSubmitStatus("loading");
      await callEcardACHPostDeposit(id, data);
      setSubmitStatus("success");

      if (import.meta.env.PROD) {
        setTimeout(() => {
          window.location.href = `${configs?.appURL}/deposit`;
        }, 3000);
      }
    } catch (err) {
      setError(getError(err));
      setSubmitStatus("error");

      if (getError(err).retry) {
        setTimeout(() => {
          setSubmitStatus("");
          setError({ message: "", retry: false });
        }, 4000);
      }
    }
  };

  if (status === "PENDING") {
    return (
      <form onSubmit={handleSubmit}>
        {error.message && (
          <div className="alert alert-danger" role="alert">
            {error.message}
          </div>
        )}

        <div className="text-left">
          <Input
            className="form-control"
            placeholder="00000"
            label={t("bank-aba-number-input-label")}
            required
            {...text("abaNumber")}
          />
        </div>

        <div className="text-left">
          <Input
            className="form-control"
            placeholder="00000"
            label={t("bank-account-number-input-label")}
            required
            {...text("accountNumber")}
          />
        </div>
        <div className="text-left">
          <Input
            className="form-control"
            placeholder="00000"
            label={t("bank-name-input-label")}
            required
            {...text("bankName")}
          />
        </div>

        <div className="text-left">
          <FileUpload
            className="form-control"
            label={t("first-bank-statement-input-label")}
            required
            onChange={selectFirstFile}
            value={firstFile}
          />
        </div>

        <div className="text-left">
          <FileUpload
            className="form-control"
            label={t("second-bank-statement-input-label")}
            onChange={selectSecondFile}
            value={secondFile}
          />
        </div>

        <div className="text-justify text-muted mb-4">
          {t("upload-bank-statement-message")}
        </div>

        <div>
          <SubmitBtn status={submitStatus} text={submitText()} />
          <SSL />
        </div>
      </form>
    );
  }

  return (
    <div className="alert alert-danger" role="alert">
      {(errMessage && errMessage) || t("echeck-deposit-failed-error")}
    </div>
  );
};

export default EcheckACHDeposit;
