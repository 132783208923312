import React, {
  createContext,
  Dispatch,
  FC,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";

import {
  getAuthToken,
  setAuthToken,
  deleteAuthToken,
  decodeAuthToken,
  ITokenInfo,
} from "utils";

export enum ActionType {
  SET_AUTH = "SET_AUTH",
}

type IAction = {
  type: ActionType.SET_AUTH;
  payload: Pick<IState, "token" | "isAuth">;
};

interface IState {
  isAuth: boolean;
  token?: string;
}

const initializeState = (token?: string): IState => ({
  token,
  isAuth: !!token,
});

const reducer = (state: IState, action: IAction): IState => {
  switch (action.type) {
    case ActionType.SET_AUTH:
      const isAuth = action.payload.isAuth;
      const token = action.payload.token;
      return {
        ...state,
        token: isAuth ? token : undefined,
        isAuth,
      };
    default:
      return state;
  }
};

interface IAuthContextValue {
  state: IState;
  decodedToken?: ITokenInfo;
  dispatch: Dispatch<IAction>;
}

const AuthContext = createContext<IAuthContextValue | undefined>(undefined);

const AuthProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducer,
    getAuthToken(),
    initializeState
  );

  useEffect(() => {
    if (state.token) {
      setAuthToken(state.token);
    } else {
      deleteAuthToken();
    }
  }, [state.token]);

  const decodedToken = useMemo<ITokenInfo | undefined>(() => {
    return state.token ? decodeAuthToken(state.token) : undefined;
  }, [state.token]);

  const value = useMemo(
    () => ({
      state,
      dispatch,
      decodedToken,
    }),
    [state, dispatch, decodedToken]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuthContext = (): IAuthContextValue => {
  const authContextValue = useContext(AuthContext);
  if (!authContextValue) {
    throw new Error("You should use useAuthContext inside of an AuthProvider");
  }

  return authContextValue;
};

export { useAuthContext, AuthProvider };
