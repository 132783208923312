import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ErrorBox from "../../Components/ErrorBox";
import CardWrap from "../../Components/CardWrap";
import DepositForm from "./DepositForm";
import { useBrand } from "context/Brand";

interface IEupaymentzDeposit {
  id: string;
  status: string;
  errMessage?: string;
  currency: number;
  amount: number;
  cardTypes?: { id: string; name: string }[] | [];
}

const EupaymentzGlobalDeposit = ({
  id,
  status,
  errMessage,
  amount,
  currency,
  cardTypes = [],
}: IEupaymentzDeposit) => {
  const { t } = useTranslation();
  const { configs } = useBrand();

  useEffect(() => {
    if (status === "COMPLETED") {
      setTimeout(() => {
        if (import.meta.env.PROD) {
          window.location.href = configs?.appURL + "/deposit";
        } else {
          console.info("SIMULATING Redirect to CRM now!");
        }
      }, 2000);
    }
  }, [status, configs]);

  if (status === "FAILED") {
    return <ErrorBox title={t("error")} text={errMessage} mode="deposit" />;
  }

  if (status === "COMPLETED") {
    return (
      <CardWrap mode="deposit">
        <h2>
          <i className="fas fa-check-circle mr-1" /> {t("success")}!
        </h2>
        <p>{t("redirecting-text")}</p>
      </CardWrap>
    );
  }

  return (
    <CardWrap mode="deposit">
      <DepositForm
        amount={amount}
        currency={currency}
        id={id}
        cardTypes={cardTypes}
      />
    </CardWrap>
  );
};

export default EupaymentzGlobalDeposit;
