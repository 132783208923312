import { AxiosInstance, AxiosRequestConfig } from "axios";

import { getAuthToken } from "utils";

export const applyAuthInterceptor = (axiosInstance: AxiosInstance): void => {
  axiosInstance.interceptors.request.use(
    (config: AuthInterceptorConfigs & AxiosRequestConfig) => {
      const { shouldAuthenticate = true } = config;
      if (shouldAuthenticate) {
        const token = getAuthToken();
        config.headers.Authorization = token ? `Bearer ${token}` : "";
      }
      return config;
    }
  );
  axiosInstance.interceptors.response.use(undefined, (error) => {
    const errorCode = error?.response?.data?.message;
    const { skipLogoutOnError } = error?.config;
    if (skipLogoutOnError) {
      // this part of the code prevents log the user out when the user cannot execute the request
      return Promise.reject(error);
    }
    if (
      errorCode === "RBAC002" ||
      errorCode === "RBAC003" ||
      errorCode === "RBAC005" ||
      errorCode === "RBAC007"
    ) {
      window.dispatchEvent(new Event("logout"));
    }
    return Promise.reject(error);
  });
};

export type AuthInterceptorConfigs = {
  /**
   * Whether should include authentication headers in the request or not
   * @default true
   */
  shouldAuthenticate?: boolean;
  /**
   * Whether should skip log user out on getting RBAC error or not
   * @default false
   */
  skipLogoutOnError?: boolean;
};
