import { IBrandConfigs } from "API/hooks";

export const mapEnvVarsToConfigs = (
  env: NodeJS.ProcessEnv
): Partial<IBrandConfigs> => ({
  apiURL: env.REACT_APP_API_URL,
  websiteName: env.REACT_APP_BRAND,
  appURL: env.REACT_APP_URL,
  enableSentry: env.REACT_APP_ENABLE_SENTRY === "true",
  enableLoggly: env.REACT_APP_ENABLE_LOGGLY === "true",
  logglyToken: env.REACT_APP_LOGGLY_TOKEN,
  zeplyAffId: env.REACT_APP_ZEPLY_AFF_ID,
});
