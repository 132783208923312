import React, { useState, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import Input from "../../Components/Forms/Input";
import { Helmet } from "react-helmet";
import { callNetellerWithdraw } from "../../API/API";
import SubmitBtn from "../../Components/Forms/SubmitBtn";
import { useFormState } from "react-use-form-state";
import Logo from "../../assets/neteller-logo.png";
import SSL from "../../Components/SSL";
import { getError } from "../../utils/index";
import { Currencies } from "@ra/utils-v2/dist/gateway";
import { useBrand } from "context/Brand";

export interface IProps {
  id: string;
  currency: number;
  amount: number;
}

const NetellerWithdrawForm = (props: IProps) => {
  const { t } = useTranslation();
  const { configs } = useBrand();
  const [formState, { email }] = useFormState();
  const [status, setStatus] = useState("");
  const [error, setError] = useState({ retry: false, message: "" });
  const { id, amount, currency } = props;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setError({ retry: false, message: "" });

    const {
      values: { email },
    } = formState;

    const data = {
      email,
    };

    try {
      setStatus("loading");
      const pymtResponse = await callNetellerWithdraw(data, id);
      setStatus("success");

      // redirect
      if (import.meta.env.PROD) {
        // wanna see success message before redirect, so wait a sec
        setTimeout(() => {
          window.location.href = pymtResponse.data.redirect;
        }, 1000);
      }
    } catch (err) {
      setError(getError(err));
      setStatus("error");

      if (getError(err).retry) {
        setTimeout(() => {
          setStatus("");
        }, 2000);
      }
    }
  };

  const submitText = () => {
    let text = t("withdraw-submit", { amount, currency: Currencies[currency] });
    return text;
  };

  return (
    <form onSubmit={handleSubmit}>
      <Helmet>
        <title className="text-capitalize">
          {t("neteller-withdraw-page-meta-title", {
            brandName: configs?.websiteName,
          })}
        </title>
      </Helmet>

      {error.message && (
        <div className="alert alert-danger" role="alert">
          {error.message}
        </div>
      )}

      <div className="form-row">
        <div className="col-12">
          <Input
            className="form-control"
            placeholder={t("email-input-placeholder")}
            icon="fas fa-envelope"
            label={t("email-input-label", { brand: "Neteller" })}
            required
            {...email("email")}
          />
        </div>

        <div className="col-12">
          <SubmitBtn status={status} text={submitText()} />

          <SSL
            logo={
              <img
                src={Logo}
                alt={t("neteller-logo-alt")}
                className="mr-3"
                style={{ height: "40px" }}
              />
            }
          />
        </div>
      </div>
    </form>
  );
};

export default NetellerWithdrawForm;
