import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { uid } from "uid";

const Pin = styled.div`
  background: white;
  min-height: 60px;
  min-width: 60px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  opacity: 0.3;
  flex-direction: column;

  &.active {
    opacity: 1;
  }

  .step-label {
    font-size: 12px;
    opacity: 0.5;
  }

  .step-number {
    font-weight: bold;
    font-size: 15px;
  }
`;

const Parting = styled.div`
  height: 1px;
  width: 100%;
  min-width: 30px;
  background: black;
  opacity: 0.15;
`;

const Container = styled.div`
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
`;

interface IProgressBar {
  step: number;
  steps: number;
}

const ProgressBar = (props: IProgressBar) => {
  const { t } = useTranslation();
  const { step, steps } = props;

  const renderSteps = () => {
    let items = [];

    for (let i = 1; i <= steps; i++) {
      let classes = "progress-pin m-4";
      if (i === step) {
        classes += " shadow active";
      }

      items.push(
        <Pin key={uid()} className={classes}>
          <div className="step-number">{i}</div>
          <div className="step-label">{t("progressbar-step-label")}</div>
        </Pin>
      );

      if (i !== steps) {
        items.push(<Parting key={uid()} />);
      }
    }

    return items;
  };

  return (
    <Container className="d-flex align-items-center justify-content-center">
      {renderSteps()}
    </Container>
  );
};

export default ProgressBar;
