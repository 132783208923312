import React, { FC } from "react";
import { RouteProps } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAuthContext } from "context/Auth";

import SentryRoute from "./SentryRoute";
import ErrorBox from "./ErrorBox";

type IPrivateRouteProps = RouteProps &
  (
    | { component: Required<RouteProps>["component"] }
    | { render: Required<RouteProps>["render"] }
  );

const PrivateRoute: FC<IPrivateRouteProps> = ({
  component: Component,
  render,
  ...rest
}) => {
  const { t } = useTranslation();
  const {
    state: { isAuth },
  } = useAuthContext();

  return (
    <SentryRoute
      {...rest}
      render={() =>
        isAuth ? (
          <SentryRoute render={render} component={Component} />
        ) : (
          <ErrorBox
            title={t("permission-denied-title")}
            text={t("permission-denied-message")}
          />
        )
      }
    />
  );
};

export { PrivateRoute };
