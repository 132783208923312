import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import CardWrap from "./CardWrap";

interface IErrorBox {
  title?: string;
  text?: string;
  mode?: string;
  /** Will prevent card from wrapping the component */
  noWrap?: boolean;
}

const ErrorBox = ({ title, text, mode = "", noWrap }: IErrorBox) => {
  const { t } = useTranslation();
  /** When CardWrap already wrapping the parent component, we don't need to duplicate */
  const Wrapper = (noWrap ? Fragment : CardWrap) as typeof CardWrap;

  return (
    <Wrapper mode={mode}>
      <div className="text-center">
        <h2>
          <i className="fas fa-exclamation-triangle mr-1" />
          {title || t("error-box-default-title")}
        </h2>
        <p>{text || t("error-box-default-message")}</p>
      </div>
    </Wrapper>
  );
};

export default ErrorBox;
