import React, { FC } from "react";

import { useTranslation } from "react-i18next";

import Button from "Components/Forms/SubmitBtn";

import { ReactComponent as CreditCardEn } from "assets/lng/en/CreditCard.svg";
import { ReactComponent as CreditCardFr } from "assets/lng/fr/CreditCard.svg";

interface IZeplySignupHelper {
  handleSignUp: () => void;
}

const ZeplySignupHelper: FC<IZeplySignupHelper> = ({ handleSignUp }) => {
  const { t, i18n } = useTranslation();

  // TODO: find bullet proof way to load svg based on language
  const CreditCard = i18n.language === "fr-FR" ? CreditCardFr : CreditCardEn;

  return (
    <div className="d-flex flex-column">
      <p>
        <strong>{t("signup-helper-title")}</strong>
      </p>
      <p>
        <strong>{t("signup-helper-subtitle")}</strong>
      </p>
      <ul className="pl-4 mb-0">
        <li>{t("first-name")}</li>
        <li>{t("last-name")}</li>
        <li>{t("first-six-digits-credit-card")}</li>
        <li>{t("last-four-digits-credit-card")}</li>
        <li>{t("expiry-date")}</li>
      </ul>
      <div className="d-flex justify-content-center pt-4">
        <CreditCard width="330px" height="210px" />
      </div>
      <div className="d-flex flex-column pt-4">
        <Button
          onClick={handleSignUp}
          text={t("continue-to-payment-gateway")}
        />
      </div>
    </div>
  );
};

export default ZeplySignupHelper;
