import { AxiosInstance } from "axios";
import MockAdapter from "axios-mock-adapter";
import data from "./mockData";

const axiosMocker = (axiosInstance: AxiosInstance) => {
  // config mock adapter
  console.warn("axios mocker is enabled!");
  const mocker = new MockAdapter(axiosInstance, { delayResponse: 1000 });

  const testTxID = "123";

  // data mocks
  mocker
    .onGet(`/deposit/${testTxID}`)
    .reply(200, data.deposit)
    .onGet(`/withdraw/${testTxID}`)
    .reply(200, data.withdraw)
    .onPost(`/deposit/${testTxID}`)
    .reply(200, data.postDeposit)
    .onGet(`/btcInvoice/${testTxID}`)
    .replyOnce(200, data.invoice)
    .onGet(`/btcInvoice/${testTxID}`)
    .reply(200, data.invoicePending)
    .onGet(`settings/all/public`)
    .reply(200, data.settings)
    .onGet(`/fe-settings`)
    .reply(200, data.feSettings);
};

export default axiosMocker;
