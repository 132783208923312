import React, { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { useTranslation } from "react-i18next";
import queryString from "query-string";

import { Currencies } from "@ra/utils-v2/dist/gateway";
import Button from "../../Components/Forms/SubmitBtn";
import { useBrand } from "context/Brand";

import Modal from "Components/Modal";
import ZeplySignupHelper from "Components/ZeplySignupHelper";

import { useShouldShowZeplySignUpHelper } from "utils/useShouldShowZeplySignUpHelper";

export interface IZeply {
  currency: number;
  amount: number;
  redirect?: string;
  meta: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    address?: string;
  };
  mode: string;
  variant: string;
  txID: string;
}

const Zeply = (props: IZeply) => {
  const { t } = useTranslation();
  const { configs } = useBrand();
  const { color, zeply } = useContext(ThemeContext);
  const qs = queryString.stringify({
    recipientWallet: props.meta.address,
    price: props.amount,
    p: configs?.zeplyAffId,
    trt: props.variant,
    currency: Currencies[props.currency],
    firstName: props.meta.firstName,
    lastName: props.meta.lastName,
    email: props.meta.email,
    phone: props.meta.phone,
    callbackURL: props.redirect,
    refId: props.txID,
  });

  const [modalIsOpen, setIsOpen] = useState(false);
  const { shouldShowHelperModal, isLoading } = useShouldShowZeplySignUpHelper();

  const handleSignUp = () => {
    window.location.href = `${import.meta.env.REACT_APP_ZEPLY_URL}/signup`;
  };

  const handleLogin = () => {
    window.location.href = `${import.meta.env.REACT_APP_ZEPLY_URL}?${qs}`;
  };

  const handleModalOpen = () => {
    setIsOpen(true);
  };

  const handleModalClose = () => {
    setIsOpen(false);
  };

  const handleSignUpButtonClick = () => {
    shouldShowHelperModal ? handleModalOpen() : handleSignUp();
  };

  return (
    <div>
      <Modal
        title={t("payment-gateway")}
        isOpen={modalIsOpen}
        onRequestClose={handleModalClose}
      >
        <ZeplySignupHelper handleSignUp={handleSignUp} />
      </Modal>
      <div className="d-flex flex-column flex-md-row">
        <div
          style={{
            background: zeply?.leftSplit?.background || color,
            color: zeply?.leftSplit?.color || "white",
          }}
          className="d-flex flex-grow-1 w-100 w-md-50 py-4 px-5"
        >
          <div>
            <h4 className="text-center">{t("zeply-first-time-user")}</h4>

            <div className="mt-2 mt-md-4 text-center">
              {t("zeply-redirect-account-creation-process")}
            </div>

            <ul className="list-unstyled d-flex flex-column align-items-start mt-3">
              <li>{t("zeply-account-creation-signup")},</li>
              <li>{t("zeply-account-creation-verify-identity")},</li>
              <li>{t("zeply-account-creation-add-card")},</li>
              <li>
                {t("zeply-account-creation-deposit", {
                  brand: configs?.websiteName,
                })}
              </li>
            </ul>
            <div className="d-flex w-100 justify-content-center mt-3">
              <Button
                text={t("sign-up")}
                onClick={handleSignUpButtonClick}
                type="button"
                color="white"
                status={isLoading ? "loading" : ""}
              />
            </div>
          </div>
        </div>

        <div
          className="d-flex flex-grow-1 w-100 w-md-50 py-4 px-5"
          style={{
            background: zeply?.rightSplit?.background || "white",
            color: zeply?.rightSplit?.color || "black",
          }}
        >
          <div className="w-100">
            <h4 className="text-center">{t("zeply-existing-user")}</h4>

            <div className="d-flex flex-column mt-5">
              <div className="mt-2 mt-md-4 text-center">
                {t("zeply-already-have-account")}
              </div>

              <div className="d-flex flex-column align-items-center mt-3">
                <div className="d-flex w-100 justify-content-center">
                  <Button
                    text={t("proceed")}
                    onClick={handleLogin}
                    type="button"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Zeply;
