import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import Input from "../../Components/Forms/Input";
import { Helmet } from "react-helmet";
import SubmitBtn from "../../Components/Forms/SubmitBtn";
import { useFormState } from "react-use-form-state";
import ProgressBar from "../../Components/ProgressBar";
import { single } from "validate.js";
import { generate } from "generate-password-ts";
import {
  callOlePayRegistration,
  callOlePayRegSendEmailCode,
  callOlePayRegSendMobileCode,
  callOlePayRegVerifyEmailCode,
  callOlePayRegVerifyMobileCode,
} from "../../API/API";
import { getError } from "../../utils/index";
// generate new pass
const newPassword = generate({
  length: 12,
  numbers: true,
  symbols: false,
  uppercase: true,
  strict: true,
});

interface Iprops {
  txId: string;
  customerEmail?: string;
  successCallback?: any;
}

const OlePayRegisterForm = (props: Iprops) => {
  const { t } = useTranslation();
  const { customerEmail, successCallback, txId } = props;

  const [formState, { email, text }] = useFormState({
    password: newPassword,
    email: customerEmail,
  });
  const [step, setStep] = useState(1);
  const [status, setStatus] = useState("");
  const [error, setError] = useState({ retry: false, message: "" });
  const [resendSMSStatus, setResendSMSStatus] = useState("normal");
  const [resendEmailStatus, setResendEmailStatus] = useState("normal");

  const resendSMSCode = async () => {
    setResendSMSStatus("loading");
    const mobileData = {
      txId,
      email: formState.values.email,
      countryCode: formState.values.countryCode,
      mobileNumber: formState.values.mobileNumber,
    };

    try {
      await callOlePayRegSendMobileCode(mobileData);
      setResendSMSStatus("success");
      setTimeout(() => {
        setResendSMSStatus("normal");
      }, 10000);
    } catch (err) {
      setResendSMSStatus("error");
    }
  };

  const resendEmailCode = async () => {
    setResendEmailStatus("loading");
    const emailData = {
      txId,
      email: formState.values.email,
      name: formState.values.firstname,
    };

    try {
      await callOlePayRegSendEmailCode(emailData);
      setResendEmailStatus("success");
      setTimeout(() => {
        setResendEmailStatus("normal");
      }, 10000);
    } catch (err) {
      setResendEmailStatus("error");
    }
  };

  const handleStepOne = () => {
    // init Loading
    setStatus("loading");

    // prep data
    const emailData = {
      txId,
      email: formState.values.email,
      name: formState.values.firstname,
    };
    const mobileData = {
      txId,
      email: formState.values.email,
      countryCode: formState.values.countryCode,
      mobileNumber: formState.values.mobileNumber,
    };

    // call both endpoints same time
    Promise.all([
      callOlePayRegSendEmailCode(emailData),
      callOlePayRegSendMobileCode(mobileData),
    ])
      .then((res) => {
        setStatus("success");
        setTimeout(() => {
          setStep(2);
          setStatus("");
        }, 2000);
      })
      .catch((err) => {
        setError(getError(err));
        setStatus("error");

        if (getError(err).retry) {
          setTimeout(() => {
            setStatus("");
          }, 2000);
        }
      });
  };

  const handleStepTwo = async () => {
    setStatus("loading");
    const data = {
      txId,
      email: formState.values.email,
      countryCode: formState.values.countryCode,
      mobileNumber: formState.values.mobileNumber,
      code: formState.values.verifySMS,
    };
    try {
      await callOlePayRegVerifyMobileCode(data);
      setStatus("success");

      setTimeout(() => {
        setStep(3);
        setStatus("");
      }, 2000);
    } catch (err) {
      setError(getError(err));
      setStatus("error");

      if (getError(err).retry) {
        setTimeout(() => {
          setStatus("");
        }, 2000);
      }
    }
  };

  const handleStepThree = async () => {
    setStatus("loading");

    const {
      values: {
        email,
        firstname,
        lastname,
        countryCode,
        mobileNumber,
        password,
        verifySMS,
        verifyEmail,
      },
    } = formState;
    const data = {
      txId,
      email,
      name: firstname,
      code: verifyEmail,
    };
    const regData = {
      txId,
      firstname,
      lastname,
      countryCode,
      mobileNumber,
      email,
      password,
      confirmPassword: password,
      smsCode: verifySMS,
      emailCode: verifyEmail,
    };
    try {
      await callOlePayRegVerifyEmailCode(data);
      await callOlePayRegistration(regData);
      setStatus("success");

      setTimeout(() => {
        // call callback function
        successCallback(password);
      }, 1500);
    } catch (err) {
      setError(getError(err));
      setStatus("error");

      if (getError(err).retry) {
        setTimeout(() => {
          setStatus("");
        }, 2000);
      }
    }
  };

  return (
    <div>
      <Helmet>
        <title>{t("olepay-registration-page-meta-title")}</title>
      </Helmet>
      <h4>{t("olepay-registration-page-title")}</h4>

      <ProgressBar steps={3} step={step} />

      {error.message && (
        <div className="alert alert-danger" role="alert">
          {error.message}
        </div>
      )}

      <form onSubmit={(e) => e.preventDefault()} className="text-left">
        {step === 1 && (
          <Fragment>
            <div className="form-row">
              <div className="col">
                <Input
                  className="form-control"
                  placeholder="John"
                  label={`${t("olepay-first-name-input-label")}*`}
                  icon="fas fa-user"
                  {...text({
                    name: "firstname",
                    validate: (val) =>
                      single(val, {
                        presence: true,
                        length: {
                          minimum: 2,
                          maximum: 20,
                        },
                      }),
                    validateOnBlur: true,
                  })}
                  errors={formState.errors.firstname}
                />
              </div>

              <div className="col">
                <Input
                  className="form-control"
                  placeholder="Smith"
                  icon="fas fa-user"
                  label={`${t("olepay-last-name-input-label")}*`}
                  {...text({
                    name: "lastname",
                    validate: (val) =>
                      single(val, {
                        presence: true,
                        length: {
                          minimum: 2,
                          maximum: 20,
                        },
                      }),
                    validateOnBlur: true,
                  })}
                  errors={formState.errors.lastname}
                />
              </div>
            </div>

            <Input
              className="form-control"
              placeholder="johnsmith@email.com"
              icon="fas fa-envelope"
              label={`${t("olepay-email-input-label-short")}*`}
              {...email({
                name: "email",
                validate: (val) =>
                  single(val, {
                    presence: true,
                    email: true,
                  }),
                validateOnBlur: true,
              })}
              errors={formState.errors.email}
            />

            <div className="form-row">
              <div className="col-4">
                <Input
                  className="form-control"
                  placeholder="00"
                  icon="fas fa-plus"
                  label={`${t("olepay-country-code-input-label")}*`}
                  {...text({
                    name: "countryCode",
                    validate: (val) =>
                      single(val, {
                        presence: true,
                        length: {
                          minimum: 1,
                          maximum: 20,
                        },
                      }),
                    validateOnBlur: true,
                  })}
                  errors={formState.errors.countryCode}
                />
              </div>

              <div className="col-8">
                <Input
                  className="form-control"
                  placeholder="000000000"
                  icon="fas fa-mobile-alt"
                  label={`${t("olepay-mobile-input-label")}*`}
                  {...text({
                    name: "mobileNumber",
                    validate: (val) =>
                      single(val, {
                        presence: true,
                        length: {
                          minimum: 6,
                          maximum: 20,
                        },
                      }),
                    validateOnBlur: true,
                  })}
                  errors={formState.errors.mobileNumber}
                />
              </div>
            </div>

            <Input
              className="form-control"
              icon="fas fa-key"
              label={t("olepay-password-input-label")}
              {...text("password")}
              value={newPassword}
            />

            <div className="text-center">
              <SubmitBtn
                status={status}
                type="button"
                onClick={handleStepOne}
              />
            </div>
          </Fragment>
        )}

        {step === 2 && (
          <Fragment>
            <Input
              className="form-control "
              placeholder={t("olepay-verification-code-input-placeholder")}
              icon="fas fa-key"
              label={`${t("olepay-verification-code-input-label")}*`}
              {...text({
                name: "verifySMS",
                validate: (val) =>
                  single(val, {
                    presence: true,
                    length: {
                      is: 6,
                    },
                  }),
                validateOnBlur: true,
              })}
              errors={formState.errors.verifySMS}
            />
            <div className="mb-3 text-center">
              <small>
                {resendSMSStatus === "normal" && (
                  <u onClick={resendSMSCode} style={{ cursor: "pointer" }}>
                    {t("re-send-code")}!
                  </u>
                )}
                {resendSMSStatus === "loading" && t("sending-text")}
                {resendSMSStatus === "error" && t("error-resending-sms")}
                {resendSMSStatus === "success" && t("sms-sent")}
              </small>
            </div>

            <div className="text-center">
              <SubmitBtn
                status={status}
                text={t("verify-phone")}
                onClick={handleStepTwo}
              />
            </div>
          </Fragment>
        )}

        {step === 3 && (
          <Fragment>
            <Input
              className="form-control"
              placeholder={t(
                "olepay-email-verification-code-input-placeholder"
              )}
              icon="fas fa-key"
              label={t("olepay-email-verification-code-input-label")}
              {...text({
                name: "verifyEmail",
                validate: (val) =>
                  single(val, {
                    presence: true,
                    length: {
                      is: 6,
                    },
                  }),
                validateOnBlur: true,
              })}
              errors={formState.errors.verifyEmail}
            />

            <div className="mb-3 text-center">
              <small>
                {resendEmailStatus === "normal" && (
                  <u onClick={resendEmailCode} style={{ cursor: "pointer" }}>
                    {t("re-send-code")}!
                  </u>
                )}
                {resendEmailStatus === "loading" && t("sending-text")}
                {resendEmailStatus === "error" && t("error-resending-email")}
                {resendEmailStatus === "success" && t("email-sent")}
              </small>
            </div>

            <div className="text-center">
              <SubmitBtn
                status={status}
                text={t("verify-email")}
                type="button"
                onClick={handleStepThree}
              />
            </div>
          </Fragment>
        )}
      </form>
    </div>
  );
};

export default OlePayRegisterForm;
