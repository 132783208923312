import React, { ReactElement } from "react";
import ErrorBox from "../Components/ErrorBox";
import LoadingBox from "../Components/LoadingBox";
import { useTranslation } from "react-i18next";

const UNAUTHORIZED = "Unauthorized";

export const RenderComponentByStatus = (
  status: string,
  error: { message: any; retry: boolean },
  mode: string,
  renderTxSwitch: () => ReactElement
): ReactElement => {
  const { t } = useTranslation();

  switch (status) {
    case "loading":
      return <LoadingBox />;
    case "error":
      let errorMessage = "An unknown error occurred";
      let title =
        error.message === UNAUTHORIZED ? t("permission-denied-title") : "";

      if (error && error.message) {
        errorMessage =
          error.message === UNAUTHORIZED
            ? t("permission-denied-message")
            : error.message;
      }

      return <ErrorBox text={errorMessage} mode={mode} title={title} />;
    case "success":
      return renderTxSwitch();
    default:
      return <LoadingBox />;
  }
};
