import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { callDragAndPayDeposit } from "API/API";
import { Loader } from "Components/LoadingBox";
import ErrorBox from "Components/ErrorBox";
import { getError } from "utils";
import { useBrand } from "context/Brand";

interface IProps {
  /** Id of transaction */
  id: string;
  /** Status of transaction */
  status: string;
  /** Possible error */
  errMessage?: string;
  /** if status == initialized we redirect to this */
  metaRedirect: string;
  /** Used automatically when the status is completed */
  automaticRedirectURL?: string;
}

const DragAndPayDeposit = ({
  id,
  status,
  errMessage,
  metaRedirect,
  automaticRedirectURL,
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  const { configs } = useBrand();
  const automaticRedirect = automaticRedirectURL ?? configs!.appURL;
  const [error, setError] = useState({ message: "", retry: false });

  const createDeposit = useCallback(async (): Promise<void> => {
    callDragAndPayDeposit(id)
      .then(({ data }) => {
        setTimeout(() => {
          window.location.href = data["Url"];
        }, 1000);
      })
      .catch((error) => setError(getError(error)));
  }, [id]);

  useEffect(() => {
    let timeout: number;
    if (status === "PENDING") {
      createDeposit();
    } else if (status === "INITIALIZED" && metaRedirect) {
      timeout = window.setTimeout(() => {
        window.location.href = metaRedirect;
      }, 1000);
    } else if (status === "COMPLETED") {
      timeout = window.setTimeout(() => {
        window.location.href = automaticRedirect;
      }, 4000);
    } else if (status === "UNCONFIRMED") {
      timeout = window.setTimeout(() => {
        window.location.href = automaticRedirect;
      }, 4000);
    }

    return (): void => {
      clearTimeout(timeout);
    };
  }, [automaticRedirect, createDeposit, id, metaRedirect, status]);

  useEffect(() => {
    let timeout: number;
    if (error.retry) {
      timeout = window.setTimeout(() => createDeposit(), 1000);
    }

    return (): void => {
      clearTimeout(timeout);
    };
  }, [createDeposit, error.retry]);

  if (status === "PENDING" || status === "INITIALIZED") return <Loader />;

  if (status === "COMPLETED") {
    return (
      <>
        <h2>
          <i className="fas fa-check-circle mr-1" />{" "}
          {t("drag-and-pay-transaction-completed")}
        </h2>

        <p>{t("redirecting-text")}</p>
      </>
    );
  }
  if (status === "UNCONFIRMED") {
    return (
      <>
        <h2>
          <i className="fas fa-check-circle mr-1" />{" "}
          {t("drag-and-pay-waiting-for-confirmation")}
        </h2>

        <p>{t("redirecting-text")}</p>
      </>
    );
  }
  if (errMessage || error.message) {
    return (
      <ErrorBox text={error.message || errMessage} mode="deposit" noWrap />
    );
  }
  return <div>{t("processed-transaction-message", { status })}</div>;
};

export default DragAndPayDeposit;
