import React from "react";
import styled from "styled-components";
import Tooltip from "Components/Tooltip";
interface IMyInputStyledProps {
  toolTipAcitve: Boolean;
}

const MyInput = styled.input<IMyInputStyledProps>`
  border: ${(props) => props.theme.input.border};
  padding: 22px;
  padding-left: 15px;
  padding-right: ${(props) => (props.toolTipAcitve ? "45px" : "0px")};
  border-radius: ${(props) => props.theme.input.borderRadius};
  font-size: 16px;
  color: ${(props) => props.theme.input.color};

  &:focus {
    border: ${(props) => props.theme.input.focusBorder};
    box-shadow: 0px 0px 5px 6px rgba(0, 0, 0, 0.05) !important;
  }

  &::placeholder {
    opacity: 0.4;
  }
`;

const InputContainer = styled.div`
  position: relative;
`;

const Icon = styled.i`
  position: absolute;
  left: 15px;
  bottom: 13px;
  font-size: 18px;
`;

const DisclaimerIcon = styled.i`
  color: #d79528;
`;

const ToolTipIcon = styled.i`
  position: absolute;
  right: 15px;
  bottom: 13px;
  font-size: 18px;
  cursor: pointer;
  color: #d79528;
`;

export const Label = styled.label`
  font-size: 15px;
  opacity: 0.7;
  width: 100%;
`;

interface Iprops {
  icon?: string;
  label?: string;
  id?: string;
  className?: any;
  placeholder?: string;
  required?: boolean;
  labelClasses?: string;
  value?: any;
  errors?: any;
  name: string;
  type?: string;
  toolTip?: boolean;
  toolTipContent?: string;
}

const Input = (props: Iprops) => {
  const {
    icon,
    label,
    labelClasses,
    toolTip = false,
    toolTipContent,
    ...inputProps
  } = props;
  const labelStyles: any = {};

  let inputStyle: any = {};

  if (props.icon) {
    inputStyle.paddingLeft = "45px";
  }

  // handle error message if there is one...
  const errors = props.errors || false;
  if (errors) {
    inputStyle.borderColor = "red";
    labelStyles.color = "red";
  }
  const shouldShowTooltip = toolTip && !!inputProps.value.length;

  const InputWarningDisclaimer = () => (
    <div className="d-flex">
      <DisclaimerIcon className="fas fa-exclamation-circle mt-1" />
      <div className="ml-2">{toolTipContent}</div>
    </div>
  );

  return (
    <div className="form-group mb-3 position-relative">
      {props.label && (
        <Label
          className={props.labelClasses}
          htmlFor={props.id}
          style={labelStyles}
        >
          {props.label}
        </Label>
      )}
      <InputContainer>
        {props.icon && <Icon className={props.icon} />}
        <MyInput
          {...inputProps}
          style={inputStyle}
          toolTipAcitve={shouldShowTooltip}
        />
        {shouldShowTooltip && (
          <Tooltip content={<InputWarningDisclaimer />}>
            <ToolTipIcon className="fas fa-exclamation-circle" />
          </Tooltip>
        )}
      </InputContainer>
      {errors && <small style={{ color: "red" }}>{errors[0]}</small>}
    </div>
  );
};

export default Input;
