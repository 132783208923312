import React from "react";
import styled from "styled-components";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

export const Button = styled.button`
  background: ${(props) => props.theme.btn.bg};
  color: ${(props) => props.theme.btn.color};
  font-weight: bold;
  padding: 11px 30px;
  border-radius: ${(props) => props.theme.btn.borderRadius};
  transition: all 0.2s linear;
  border: none;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
    pointer-events: none;
  }

  &.btn-success {
    background: ${(props) => props.theme.colorSuccess};
  }

  &.btn-error {
    background: red;
  }

  &.btn-white {
    background: white;
    color: black;
  }
`;

interface iProps {
  text?: string;
  status?: "loading" | "error" | "success" | string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  onClick?: any;
  color?: string;
}

const SubmitBtn = ({
  text,
  status,
  disabled,
  type,
  onClick,
  color,
}: iProps) => {
  const { t } = useTranslation();
  const btnClasses = classnames({
    "btn-success": status === "success",
    "btn-error": status === "error",
    "btn-white": color === "white",
  });

  const btnType = () => {
    if (type) {
      return type;
    }
    return "submit";
  };

  const btnDisabled = () => {
    if (
      disabled ||
      status === "loading" ||
      status === "error" ||
      status === "success"
    ) {
      return true;
    }

    return false;
  };

  return (
    <Button
      className={btnClasses}
      disabled={btnDisabled()}
      type={btnType()}
      onClick={onClick}
    >
      {!status && (text || t("button-default-text"))}
      {status === "loading" && (
        <span>
          <i className="fas fa-spinner fa-spin mr-1" />
          {t("loading-text")}
        </span>
      )}
      {status === "error" && (
        <span>
          <i className="fas fa-times-circle mr-1" />
          {t("error")}
        </span>
      )}
      {status === "success" && (
        <span>
          <i className="fas fa-check mr-1" />
          {t("success")}
        </span>
      )}
    </Button>
  );
};

export default SubmitBtn;
