import React, { FC } from "react";
import ReactModal from "react-modal";
import styled from "styled-components";

import XIcon from "assets/XIcon.svg?url";

interface IModal {
  isOpen: boolean;
  children: React.ReactNode;
  onRequestClose: () => void;
  title: string;
}
const CloseButton = styled.div`
  display: flex;
  cursor: pointer;
`;

const Modal: FC<IModal> = ({ onRequestClose, isOpen, title, children }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          maxWidth: "700px",
          height: "min-content",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
        },
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
        },
      }}
    >
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between align-items-center">
          <h5>{title}</h5>
          <CloseButton role="button" onClick={onRequestClose}>
            <img src={XIcon} alt="close-icon" />
          </CloseButton>
        </div>
        <div className="border-bottom w-100 pt-2" />
        <div className="pt-4">{children}</div>
      </div>
    </ReactModal>
  );
};

export default Modal;
