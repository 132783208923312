import React, { ComponentType, FC } from "react";

type Wrappers<T extends unknown[]> = {
  [P in keyof T]: T[P] extends T[number] ? [ComponentType<T[P]>, T[P]] : never;
};

export const composeWrappers =
  <T extends any[]>(...wrappers: Wrappers<T>): FC =>
  ({ children }): JSX.Element => {
    return (
      <>
        {wrappers.reverse().reduce(
          (acc, [Wrapper, props]) => (
            <Wrapper {...props}>{acc}</Wrapper>
          ),
          children
        )}
      </>
    );
  };
