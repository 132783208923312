import React, { createContext, FC, useContext, useMemo } from "react";
import { ThemeProvider } from "styled-components";
import Helmet from "react-helmet";
import _merge from "lodash.merge";
import { Theme as unbrandedTheme } from "@/Theme";

import {
  IBrandConfigs,
  IBrandSettings,
  useBrandSettingsQuery,
} from "API/hooks";
import { myAxios } from "API/API";
import { mapEnvVarsToConfigs } from "utils/brand";
import { FAV_ICONS } from "consts";

const BrandMetaTags = () => {
  const { configs } = useBrand();
  const { fontURL, bucketURL } = configs || {};
  const isDev = import.meta.env.DEV;
  const baseURL =
    configs?.bucketURL || !isDev
      ? `${bucketURL}/favicons`
      : `${import.meta.env.PUBLIC_URL}/brand/assets/favicons`;

  return (
    <Helmet>
      <link href={fontURL} rel="stylesheet" />
      {FAV_ICONS.map((favIcon) => (
        <link
          key={favIcon.name}
          {...favIcon}
          href={`${baseURL}/${favIcon.name}`}
        />
      ))}
    </Helmet>
  );
};

const BrandContext = createContext<Partial<IBrandSettings>>({
  configs: undefined,
  theme: undefined,
});

export const BrandProvider: FC = ({ children }) => {
  const { data: brandSettings, isLoading, error } = useBrandSettingsQuery();

  const { theme: brandTheme, configs } = brandSettings || {};
  const mergedTheme = _merge(unbrandedTheme, brandTheme);

  const memoizedConfig = useMemo(
    () => ({
      theme: mergedTheme,
      configs: {
        ...(mapEnvVarsToConfigs(import.meta.env) as IBrandConfigs),
        ...configs,
      },
    }),
    [mergedTheme, configs]
  );

  myAxios.defaults.baseURL = memoizedConfig.configs.apiURL;

  if (isLoading) {
    return (
      <i
        className="fas fa-spinner fa-spin position-absolute"
        style={{ left: "50%", top: "50%" }}
      />
    );
  }

  if (error || !configs) {
    throw new Error(
      "Couldn't fetch the brand settings, Make sure runtime config is configured correctly."
    );
  }

  return (
    <BrandContext.Provider value={memoizedConfig}>
      <BrandMetaTags />
      <ThemeProvider theme={memoizedConfig.theme}>{children}</ThemeProvider>
    </BrandContext.Provider>
  );
};

export const useBrand = () => {
  const context = useContext(BrandContext);
  if (!context) {
    throw new Error(`You should use useBrand inside of an BrandProvider`);
  }

  return context;
};
