import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import Button from "../../Components/Forms/SubmitBtn";
import SSL from "../../Components/SSL";
import { format } from "@buttercup/react-formatted-input";
import { Currencies } from "@ra/utils-v2/dist/gateway";
import CardWrap from "../../Components/CardWrap";
import ErrorBox from "../../Components/ErrorBox";
import { callQuickcardPostDeposit } from "../../API/API";
import { getError } from "../../utils/index";
import { useBrand } from "context/Brand";

const cardNoFormat = [
  { char: /\d/, repeat: 4 },
  { exactly: " " },
  { char: /\d/, repeat: 4 },
  { exactly: " " },
  { char: /\d/, repeat: 4 },
  { exactly: " " },
  { char: /\d/, repeat: 4 },
];

const cvcFormat = [{ char: /\d/, repeat: 3 }];

const expFormat = [
  { char: /\d/, repeat: 2 },
  { exactly: "/" },
  { char: /\d/, repeat: 2 },
];

interface IQuickcardDeposit {
  id: string;
  status: string;
  errMessage?: string;
  currency: number;
  amount: number;
}

interface IsubmitStatus {
  message: string;
  status: "error" | "success" | "loading" | "";
}

const QuickcardDeposit = ({
  status,
  amount,
  currency,
  id,
  errMessage,
}: IQuickcardDeposit) => {
  const { t } = useTranslation();
  const { configs } = useBrand();

  useEffect(() => {
    if (status !== "PENDING") {
      setTimeout(() => {
        window.location.href = `${configs?.appURL}/deposit`;
      }, 4000);
    }
  }, [status, configs]);

  const [state, setState] = useState({
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    number: "",
    raw: {
      cvc: "",
      expiryM: "",
      expiryY: "",
      number: "",
    },
  });
  const [submitStatus, setSubmitStatus] = useState<IsubmitStatus>({
    message: "",
    status: "",
  });

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const { expiryM, expiryY } = state.raw;
    const { name, number, cvc } = state;
    const submitData = {
      cardHolderName: name,
      cardNumber: number,
      expDate: `${expiryM}${expiryY}`,
      cardCvv: cvc,
    };

    try {
      setSubmitStatus({ status: "loading", message: "" });
      const {
        data: { message },
      } = await callQuickcardPostDeposit(id, submitData);
      setSubmitStatus({ status: "success", message });
      setTimeout(() => {
        window.location.href = `${configs?.appURL}/deposit`;
      }, 4000);
    } catch (err) {
      const { message, retry } = getError(err);
      setSubmitStatus({ status: "error", message });

      if (retry) {
        setTimeout(() => {
          setSubmitStatus({ status: "", message: "" });
        }, 4000);
      }
    }
  };

  const submitText = () => {
    let text = t("pay-submit-button", {
      amount,
      currency: Currencies[currency],
    });
    return text;
  };

  const handleChange = (event: any) => {
    const { value, name } = event.target;

    if (name === "number") {
      const { formatted, raw } = format(value, cardNoFormat);
      setState({
        ...state,
        number: formatted,
        raw: {
          ...state.raw,
          number: raw,
        },
      });
    }

    if (name === "cvc") {
      const { formatted, raw } = format(value, cvcFormat);
      setState({
        ...state,
        cvc: formatted,
        raw: {
          ...state.raw,
          cvc: raw,
        },
      });
    }

    if (name === "expiry") {
      const { formatted } = format(value, expFormat);
      const splitDate = formatted.split("/");

      setState({
        ...state,
        expiry: formatted,
        raw: {
          ...state.raw,
          expiryM: splitDate[0],
          expiryY: splitDate[1],
        },
      });
    }

    if (name === "name") {
      setState({
        ...state,
        name: value,
      });
    }
  };

  const handleFocus = (e: any) => {
    setState({ ...state, focus: e.target.name });
  };

  if (submitStatus.status === "success") {
    return (
      <CardWrap mode="deposit">
        <div className="text-center">
          <h2>
            <i className="fas fa-check mr-1" />
            {t("success")}
          </h2>
          <p>{submitStatus.message}</p>
          <p className="text-muted">
            <i className="fas fa-spinner fa-spin mr-1" />
            {t("redirecting-text")}
          </p>
        </div>
      </CardWrap>
    );
  }

  if (status === "PENDING") {
    return (
      <CardWrap mode="deposit">
        <form onSubmit={handleSubmit}>
          {submitStatus.status === "error" && (
            <div className="alert alert-danger" role="alert">
              {submitStatus.message}
            </div>
          )}

          <Cards
            cvc={state.cvc}
            expiry={state.expiry}
            // @ts-ignore
            focused={state.focus}
            name={state.name}
            number={state.number}
          />
          <div className="mt-3 mb-3">
            <input
              className="form-control mb-2"
              type="text"
              name="name"
              value={state.name}
              onChange={handleChange}
              placeholder={t("full-name-input-placeholder")}
              onFocus={handleFocus}
              required
            />
            <input
              className="form-control mb-2"
              type="text"
              name="number"
              value={state.number}
              onChange={handleChange}
              placeholder={t("card-number-input-placeholder")}
              onFocus={handleFocus}
              required
            />
            <div className="d-flex">
              <input
                className="form-control mr-1"
                type="text"
                name="expiry"
                value={state.expiry}
                onChange={handleChange}
                placeholder="mm/yy"
                onFocus={handleFocus}
                required
              />
              <input
                className="form-control ml-1"
                type="text"
                name="cvc"
                value={state.cvc}
                onChange={handleChange}
                placeholder="CVC"
                onFocus={handleFocus}
                required
              />
            </div>
          </div>

          <div>
            <Button
              text={submitText()}
              type="submit"
              status={submitStatus.status}
            />
            <SSL />
          </div>
        </form>
      </CardWrap>
    );
  }

  return (
    <ErrorBox mode="deposit" title={t("error-box-title")} text={errMessage} />
  );
};

export default QuickcardDeposit;
