import React from "react";
import Countdown, { zeroPad } from "react-countdown";
import { useTranslation } from "react-i18next";

interface IProps {
  countdownTo: number;
}

const MyCountdown = ({ countdownTo = 0 }: IProps) => {
  const { t } = useTranslation();

  return (
    <h4>
      <Countdown
        date={countdownTo}
        renderer={({ minutes, seconds, completed }) => {
          if (completed) {
            return (
              <span className="text-danger">{t("countdown-completed")}</span>
            );
          } else {
            return (
              <span>
                <i className="far fa-clock mr-1" />
                {zeroPad(minutes)}:{zeroPad(seconds)}
              </span>
            );
          }
        }}
      />
    </h4>
  );
};

export default MyCountdown;
