import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";

import { AuthProvider } from "context/Auth";
import { queryClient } from "utils/queryClient";
import { QueryClientProvider } from "react-query";
import { composeWrappers } from "utils/reactUtils";
import { FeatureFlagProvider } from "utils/featureFlags";

import App from "./App";
import { BrandProvider } from "context/Brand";
import { SentryProvider } from "utils/sentry";
import { LoggerProvider } from "utils/Logger";
import browserHistory from "./history";

const Providers = composeWrappers<any>(
  [Suspense, { fallback: "" }],
  [QueryClientProvider, { client: queryClient }],
  [BrandProvider, {}],
  [Router, { history: browserHistory }],
  [SentryProvider, {}],
  [AuthProvider, {}],
  [FeatureFlagProvider, {}],
  [LoggerProvider, {}]
);

ReactDOM.render(
  <Suspense fallback="">
    <Providers>
      <App />
    </Providers>
  </Suspense>,
  document.getElementById("root")
);
