import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import "react-credit-cards/es/styles-compiled.css";
import CardWrap from "../../Components/CardWrap";
import ErrorBox from "../../Components/ErrorBox";
import { callGreenBoxPostDeposit } from "../../API/API";
import { getError } from "../../utils/index";
import { useBrand } from "context/Brand";

interface IGreenBoxDeposit {
  id: string;
  status: string;
  errMessage?: string;
}

interface IsubmitStatus {
  message: string;
  status: "error" | "success" | "loading" | "";
}

const GreenBoxDeposit = ({ status, id, errMessage }: IGreenBoxDeposit) => {
  const { t } = useTranslation();
  const { configs } = useBrand();

  const handleSubmit = useCallback(async () => {
    try {
      setSubmitStatus({ status: "loading", message: "" });
      const {
        data: { redirectUrl },
      } = await callGreenBoxPostDeposit(id);
      setSubmitStatus({ status: "success", message: "" });
      setTimeout(() => {
        window.location.href = redirectUrl;
      }, 1000);
    } catch (err) {
      const { message, retry } = getError(err);
      setSubmitStatus({ status: "error", message });

      if (retry) {
        setTimeout(() => {
          setSubmitStatus({ status: "", message: "" });
        }, 4000);
      }
    }
  }, [id]);

  useEffect(() => {
    if (status !== "PENDING") {
      setTimeout(() => {
        window.location.href = `${configs?.appURL}/deposit`;
      }, 4000);
    } else if (status === "PENDING") {
      handleSubmit();
    }
  }, [status, handleSubmit, configs]);

  const [submitStatus, setSubmitStatus] = useState<IsubmitStatus>({
    message: "",
    status: "",
  });

  if (submitStatus.status === "success") {
    return (
      <CardWrap mode="deposit">
        <div className="text-center">
          <h2>
            <i className="fas fa-check mr-1" />
            {t("success")}
          </h2>
          <p>{submitStatus.message}</p>
          <p className="text-muted">
            <i className="fas fa-spinner fa-spin mr-1" />
            {t("redirecting-text")}
          </p>
        </div>
      </CardWrap>
    );
  }

  if (status === "PENDING") {
    return (
      <CardWrap>
        <h4 className="text-muted mb-0">
          <i className="fas fa-spinner fa-spin" /> {t("loading-text")}
        </h4>
      </CardWrap>
    );
  }

  return (
    <ErrorBox mode="deposit" title={t("error-box-title")} text={errMessage} />
  );
};

export default GreenBoxDeposit;
