export const FAV_ICONS = [
  { rel: "icon", type: "image/x-icon", name: "favicon.ico" },
  { rel: "icon", type: "image/png", sizes: "16x16", name: "favicon-16x16.png" },
  { rel: "icon", type: "image/png", sizes: "32x32", name: "favicon-32x32.png" },
  { rel: "icon", type: "image/png", sizes: "48x48", name: "favicon-48x48.png" },
  {
    rel: "icon",
    type: "image/png",
    sizes: "192x192",
    name: "android-chrome-192x192.png",
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "512x512",
    name: "android-chrome-512x512.png",
  },
  {
    rel: "apple-touch-icon",
    sizes: "180x180",
    name: "apple-touch-icon-180x180.png",
  },
  {
    rel: "apple-touch-icon",
    sizes: "1024x1024",
    name: "apple-touch-icon-1024x1024.png",
  },
];
