import React, { FC, useMemo } from "react";
import { getFrontendAdapter } from "@ra/feature-flags";
import { usePublicSettingsQuery } from "API/hooks";
import { myAxios } from "API/API";

import { useTxId } from "./useTxId";

export const FeatureFlagProvider: FC = ({ children }) => {
  const { data, isFetched } = usePublicSettingsQuery();
  const txId = useTxId();

  const brandName =
    data?.data.find((item: any) => item.name === "brand")?.data.name || "";

  // TODO: Remove any and solve type issue
  const FeatureFlagProvider: any = useMemo(() => {
    if (isFetched) {
      return getFrontendAdapter({
        axiosInstance: myAxios,
        txId,
      }).FeatureFlagProvider;
    }
  }, [isFetched, brandName, txId]);

  return FeatureFlagProvider ? (
    <FeatureFlagProvider>{children}</FeatureFlagProvider>
  ) : null;
};

export { useFeatureFlag } from "@ra/feature-flags";
