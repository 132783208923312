import i18n from "../i18n";

export const getError = ({
  response: {
    data: {
      message = i18n.t("generic-error-message") as string,
      retry = false,
    } = {},
  } = {},
}: any = {}) => ({ message, retry });

export const translateCryptoSymbol = (symbol: string): string => {
  switch (symbol) {
    case "BTC":
      return "Bitcoin";
    case "BTC_LIGHTNING":
      return "Bitcoin (Lightning network)";
    case "LTC":
      return "Litecoin";
    case "ETH":
      return "Ethereum";
    case "XRP":
      return "Ripple";
    case "DOGE":
      return "Dogecoin";
    case "ETH_USDT":
      return "Tether (ERC-20)";
    case "ETH_TUSD":
      return "True USD (ERC-20)";
    case "ETH_USDC":
      return "USD Coin (ERC-20)";
    case "ETH_PAX":
      return "Paxos Standard (ERC-20)";
    case "ETH_GUSD":
      return "Gemini Dollar (ERC-20)";
    case "USDT-TRX":
      return "Tether (TRC-20)";
    case "USDC-TRX":
      return "USD Coin (TRC-20)";
    case "USDT-BSC":
      return "Tether (BEP-20)";
    case "USDC-BSC":
      return "USD Coin (BEP-20)";
    default:
      return symbol;
  }
};

export const getPaymentOptionsKindToNameMap = (appSettings: {
  data: any[];
}) => {
  return (
    appSettings?.data?.find(
      (item: any) => item.name === "paymentMethodOptionsKindToNameMap"
    )?.data || {}
  );
};
