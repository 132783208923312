import React, { FC, FormEvent, useEffect, useMemo, useState } from "react";
import Input from "../../Components/Forms/Input";
import { Helmet } from "react-helmet";
import { callCryptoBtcWithdraw } from "../../API/API";
import SubmitBtn from "../../Components/Forms/SubmitBtn";
import { useFormState } from "react-use-form-state";
import { getError, getPaymentOptionsKindToNameMap } from "../../utils/index";
import { Currencies } from "@ra/utils-v2/dist/gateway";
import { Trans, useTranslation } from "react-i18next";
import { useBrand } from "context/Brand";
import BubbleAlert from "Components/BubbleAlert";
import CryptoNetworkWarningMessage from "./CryptoNetworkMessage";
import { networkData, ICryptoNetwork, getNetworkMapped } from "./consts";
import { usePublicSettingsQuery } from "API/hooks";

export interface IProps {
  id: string;
  currency: number;
  amount: number;
  cryptoKind: null | string;
}

const CryptoBtcWithdrawForm: FC<IProps> = ({
  id,
  amount,
  currency,
  cryptoKind,
}) => {
  const { t } = useTranslation();
  const { configs } = useBrand();
  const [formState, { text }] = useFormState();
  const [status, setStatus] = useState<string>("");
  const [error, setError] = useState<any>({ message: "", retry: false });

  const [cryptoNetwork, setCryptoNetwork] = useState<ICryptoNetwork | null>(
    null
  );

  const { data: appSettings } = usePublicSettingsQuery();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setError({ message: "", retry: false });

    const {
      values: { address, tag },
    } = formState;

    const data = {
      address,
      tag,
    };

    try {
      setStatus("loading");
      const pymtResponse = await callCryptoBtcWithdraw(data, id);
      setStatus("success");

      // redirect
      if (import.meta.env.PROD) {
        // wanna see success message before redirect, so wait a sec
        setTimeout(() => {
          window.location.href = pymtResponse.data.redirect;
        }, 1000);
      }
    } catch (err) {
      setError(getError(err));
      setStatus("error");

      if (getError(err).retry) {
        setTimeout(() => {
          setStatus("");
        }, 2000);
      }
    }
  };

  const submitText = () => {
    let text = t("withdraw-submit", {
      amount,
      currency: Currencies[currency],
    });
    return text;
  };

  const cryptoCurrency = useMemo(() => {
    const symbolToKindMapping = getPaymentOptionsKindToNameMap(appSettings);

    return symbolToKindMapping?.[cryptoKind || "BTC"] ?? (cryptoKind || "BTC");
  }, [appSettings, cryptoKind]);

  // Logic built for crypto network warning to be mapped and matched with only those defined in CRYPTO_NETWORKS_MAP
  useEffect(() => {
    if (!cryptoKind) return;

    const withdrawNetworkMapped = getNetworkMapped(cryptoKind);

    setCryptoNetwork(networkData?.[withdrawNetworkMapped]);
  }, [cryptoKind]);

  return (
    <form onSubmit={handleSubmit}>
      <Helmet>
        <title className="text-capitalize">
          {t("crypto-withdraw-page-meta-title", {
            brandName: configs?.websiteName,
          })}
        </title>
      </Helmet>

      {error.message && (
        <div className="alert alert-danger" role="alert">
          {error.message}
        </div>
      )}

      <div className="form-row">
        <div className="col-12">
          <Input
            className="form-control"
            placeholder={t("crypto-withdraw-input-placeholder", {
              currency: cryptoCurrency,
            })}
            icon={`${cryptoKind ? "fa fa-wallet" : "fab fa-bitcoin"}`}
            label={t("crypto-withdraw-input-label")}
            required
            toolTip={true}
            toolTipContent={t("input-correct-destination-disclaimer")}
            {...text("address")}
          />
        </div>
      </div>

      {cryptoKind === "XRP" && (
        <div className="form-row mb-3">
          <div className="col-12">
            <Input
              className="form-control"
              placeholder={t("XRP-tag-input-placeholder", {
                currency: cryptoCurrency,
              })}
              icon="fa fa-tag"
              {...text("tag")}
            />
            <small className="text-muted">{t("XRP-tag-input-notice")}</small>
          </div>
        </div>
      )}

      <BubbleAlert icon="fas fa-exclamation-circle">
        <div className="font-weight-bold">
          <Trans
            i18nKey="crypto-withdraw-address-used-once"
            values={{
              currency: cryptoCurrency,
            }}
          />
        </div>

        <div className="mt-2">
          <p>{t("crypto-withdraw-address-reuse")}</p>{" "}
          <span className="font-weight-bold">
            {t("crypto-withdraw-correct-address")}
          </span>
        </div>

        {cryptoNetwork && (
          <CryptoNetworkWarningMessage cryptoNetwork={cryptoNetwork} />
        )}
      </BubbleAlert>

      <div className="form-row mt-4">
        <div className="col-12">
          <SubmitBtn status={status} text={submitText()} />
        </div>
      </div>
    </form>
  );
};

export default CryptoBtcWithdrawForm;
