import { Route } from "react-router-dom";
import * as Sentry from "@sentry/react";

/**
 * Create Custom Sentry Route component
 * @see https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/#react-router-v4v5
 */
const SentryRoute = Sentry.withSentryRouting(Route);

export default SentryRoute;
