import React, { useState, FormEvent, Fragment } from "react";
import { useTranslation } from "react-i18next";
import Input from "../../Components/Forms/Input";
import { Helmet } from "react-helmet";
import { callUPayDepositStep1 } from "../../API/API";
import SubmitBtn from "../../Components/Forms/SubmitBtn";
import { useFormState } from "react-use-form-state";
import UPayLogo from "../../assets/upay-logo.png";
import UPayDepositStep2Form from "./UPayDepositStep2Form";
import ProgressBar from "../../Components/ProgressBar";
import { getError } from "../../utils/index";
import SSL from "../../Components/SSL";
import { useBrand } from "context/Brand";

export interface IProps {
  id: string;
  currency: number;
  amount: number;
}

const UPayDepositForm = (props: IProps) => {
  const { t } = useTranslation();
  const { configs } = useBrand();
  const [formState, { text }] = useFormState();
  const [status, setStatus] = useState("");
  const [tokenNumber, setTokenNumber] = useState("");
  const [error, setError] = useState({ retry: false, message: "" });
  const { id, amount, currency } = props;
  const [step, setStep] = useState(1);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setError({ retry: false, message: "" });

    const {
      values: { sender },
    } = formState;

    const data = {
      sender,
    };

    try {
      setStatus("loading");
      const paymentData = await callUPayDepositStep1(data, id);
      setStatus("success");
      const tokenNo = paymentData.data.tokenNumber;
      setTokenNumber(tokenNo);

      // show step 2
      // wanna see success message before rendering form 2
      setTimeout(() => {
        setStatus("");
        setStep(2);
      }, 1000);
    } catch (err) {
      setError(getError(err));
      setStatus("error");

      if (getError(err).retry) {
        setTimeout(() => {
          setStatus("");
        }, 2000);
      }
    }
  };

  return (
    <Fragment>
      <ProgressBar steps={2} step={step} />

      {step === 1 && (
        <form onSubmit={handleSubmit}>
          <Helmet>
            <title>
              {t("upay-deposit-page-meta-title", {
                brandName: configs?.websiteName,
              })}
            </title>
          </Helmet>

          {error.message && (
            <div className="alert alert-danger" role="alert">
              {error.message}
            </div>
          )}

          <Input
            className="form-control"
            placeholder="Username"
            icon="fas fa-user"
            label={t("upay-username-input-label")}
            required
            {...text("sender")}
          />

          <SubmitBtn status={status} />
        </form>
      )}

      {step === 2 && (
        <UPayDepositStep2Form
          id={id}
          currency={currency}
          amount={amount}
          tokenNumber={tokenNumber}
        />
      )}

      <SSL
        logo={
          <img
            src={UPayLogo}
            alt={t("upay-logo-alt")}
            className="mr-3"
            style={{ height: "40px" }}
          />
        }
      />
    </Fragment>
  );
};

export default UPayDepositForm;
