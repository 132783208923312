import React, { useState, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import Input from "../../Components/Forms/Input";
import { Helmet } from "react-helmet";
import { callOlePayWithdraw } from "../../API/API";
import SubmitBtn from "../../Components/Forms/SubmitBtn";
import { useFormState } from "react-use-form-state";
import OlePayLogo from "../../assets/olepay-logo.png";
import { getError } from "../../utils/index";
import SSL from "../../Components/SSL";
import { Currencies } from "@ra/utils-v2/dist/gateway";
import { useBrand } from "context/Brand";

export interface FormProps {
  id: string;
  currency: number;
  amount: number;
}

const OlePayWithdrawForm = (props: FormProps) => {
  const { t } = useTranslation();
  const { configs } = useBrand();
  const [formState, { email }] = useFormState();
  const [status, setStatus] = useState("");
  const [error, setError] = useState({ retry: false, message: "" });
  const { id, currency, amount } = props;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setError({ retry: false, message: "" });

    const {
      values: { customerEmail },
    } = formState;

    const data = {
      customerEmail,
    };

    try {
      setStatus("loading");
      const res = await callOlePayWithdraw(data, id);
      setStatus("success");

      // show step 2
      if (import.meta.env.PROD) {
        // wanna see success message before redirect, so wait a sec
        setTimeout(() => {
          window.location.href = res.data.redirect;
        }, 1000);
      }
    } catch (err) {
      setError(getError(err));
      setStatus("error");

      if (getError(err).retry) {
        setTimeout(() => {
          setStatus("");
        }, 2000);
      }
    }
  };

  const submitText = () => {
    let text = t("withdraw-submit", {
      amount,
      currency: Currencies[currency],
    });
    return text;
  };

  return (
    <form onSubmit={handleSubmit}>
      <Helmet>
        <title>
          {t("olepay-withdraw-page-meta-title", {
            brandName: configs?.websiteName,
          })}
        </title>
      </Helmet>

      {error.message && (
        <div className="alert alert-danger" role="alert">
          {error.message}
        </div>
      )}

      <Input
        className="form-control"
        placeholder={t("email-input-placeholder")}
        icon="fas fa-user"
        label={t("olepay-customer-email-input-label")}
        required
        {...email("customerEmail")}
      />

      <SubmitBtn status={status} text={submitText()} />

      <SSL
        logo={
          <img
            src={OlePayLogo}
            alt={t("olepay-logo-alt")}
            className="mr-3"
            style={{ height: "40px" }}
          />
        }
      />
    </form>
  );
};

export default OlePayWithdrawForm;
