import { FC, useEffect } from "react";
import * as Sentry from "@sentry/react";

import { useBrand } from "context/Brand";
import { usePublicSettingsQuery } from "API/hooks";
import browserHistory from "../history";

export const SentryProvider: FC = ({ children }) => {
  const { configs } = useBrand();
  const { data: appSettings } = usePublicSettingsQuery();

  useEffect(() => {
    const clusterName = import.meta.env.REACT_APP_CLUSTER_NAME || "development";
    // We are building docker image on stg pipeline, so CLUSTER_NAME of prod is stg
    const shouldCaptureReplays = clusterName === "stg";
    if (!configs) return;

    const API_URL_REGEX = new RegExp(`${configs.apiURL}/`);

    Sentry.init({
      enabled: configs?.enableSentry || false,
      environment: import.meta.env.PROD ? "production" : "development",
      dsn: import.meta.env.REACT_APP_SENTRY_DSN,
      replaysOnErrorSampleRate: shouldCaptureReplays
        ? Number(import.meta.env.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || 0.1)
        : 0,
      replaysSessionSampleRate: Number(
        import.meta.env.SENTRY_REPLAYS_SESSION_SAMPLE_RATE || 0
      ),
      integrations: [
        Sentry.reactRouterV5BrowserTracingIntegration({
          history: browserHistory,
        }),
        Sentry.extraErrorDataIntegration(),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: Number(
        import.meta.env.SENTRY_TRACES_SAMPLE_RATE || 0.001
      ),
      tracePropagationTargets: ["localhost", API_URL_REGEX],
      release: import.meta.env.REACT_APP_SENTRY_RELEASE,
    });

    Sentry.setTag("cluster_name", clusterName);
  }, [configs]);

  useEffect(() => {
    const brandName =
      appSettings?.data.find((item: any) => item.name === "brand")?.data.name ||
      "";

    if (brandName) {
      Sentry.setTag("brand_name", brandName);
    }
  }, [appSettings]);

  return <>{children}</>;
};
