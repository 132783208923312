import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import DepositInfo from "./DepositInfo";
import CryptoBtcCurrencyForm from "./CryptoBtcCurrencyForm";

interface IProps {
  status: string;
  id: string;
  redirectUrl: string;
  cryptoCurrencies: string[];
}

const CryptoBtcDeposit = ({
  status,
  id,
  cryptoCurrencies,
  redirectUrl,
}: IProps) => {
  const { t } = useTranslation();
  const [goToInitialized, setGoToInitialized] = useState(false);

  const callback = useCallback(() => setGoToInitialized(true), []);

  if (status === "INITIALIZED" || goToInitialized) {
    return <DepositInfo redirectUrl={redirectUrl} id={id} />;
  }

  if (status === "PENDING") {
    return (
      <CryptoBtcCurrencyForm
        id={id}
        cryptoCurrencies={cryptoCurrencies}
        cb={callback}
      />
    );
  }

  return <div>{t("processed-transaction-message", { status })}</div>;
};

export default CryptoBtcDeposit;
