import React from "react";
import GoBack from "../Components/GoBack";

const CardWrap = ({
  children,
  classes = "col-sm-8 col-lg-6",
  childrenWrapperClasses = "card shadow mb-4 p-4 pt-5 pb-5 p-lg-5 text-center",
  mode,
}: any) => {
  return (
    <div className={classes}>
      <div style={{ borderRadius: "10px" }} className={childrenWrapperClasses}>
        {children}
      </div>
      <GoBack mode={mode} />
    </div>
  );
};

export default CardWrap;
