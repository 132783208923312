import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import Tippy from "@tippyjs/react";
import { Placement } from "tippy.js";

const TooltipText = styled.p`
  padding: 10px 20px 0px 20px;
  font-size: 14px;
`;

interface IStyledTippyProps {
  children: any;
}

const StyledTippy = styled(Tippy)<IStyledTippyProps>`
  position: relative;
  z-index: 5000;
  background: white;
  border: 1px solid #d79528;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ITooltipProps {
  content: ReactNode;
  placement?: Placement;
  maxWidth?: number;
}

const Tooltip: FC<ITooltipProps> = (
  { content, children, placement = "bottom-end", maxWidth = 400 },
  ...props
) => {
  return (
    <StyledTippy
      showOnCreate
      offset={[0, 20]}
      maxWidth={maxWidth}
      content={<TooltipText>{content}</TooltipText>}
      placement={placement}
      {...props}
    >
      {children}
    </StyledTippy>
  );
};

export default Tooltip;
