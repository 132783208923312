import React from "react";
import { useTranslation } from "react-i18next";

import CardWrap from "./CardWrap";

export const Loader = () => {
  const { t } = useTranslation();

  return (
    <h4 className="text-muted mb-0">
      <i className="fas fa-spinner fa-spin" /> {t("loading-text")}
    </h4>
  );
};

const LoadingBox = () => (
  <CardWrap>
    <Loader />
  </CardWrap>
);

export default LoadingBox;
