import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {
  AVAILABLE_LANGUAGES,
  AVAILABLE_LANGUAGES_VALUES,
} from "@ra/utils-v2/dist/crm-fe";

const USER_LANGUAGE_KEY = `userLng`;

i18n
  // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: AVAILABLE_LANGUAGES.en,
    whitelist: AVAILABLE_LANGUAGES_VALUES,
    returnEmptyString: false,
    detection: {
      order: ["querystring", "cookie", "localStorage"],
      caches: ["localStorage"],
      lookupCookie: USER_LANGUAGE_KEY,
      lookupLocalStorage: USER_LANGUAGE_KEY,
    },
  });

export default i18n;
