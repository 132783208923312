import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

const CopyToClipBoard = (props: { value: string; text?: string }) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const handleAction = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <small>
      {!copied && (
        <CopyToClipboard text={props.value} onCopy={handleAction}>
          <span style={{ cursor: "pointer", color: "#444" }}>
            <i className="far fa-clipboard mr-1" />
            {props.text || t("copy-to-clipboard-cta")}
          </span>
        </CopyToClipboard>
      )}
      {copied && (
        <span className="text-success">
          <i className="fas fa-check mr-1" />
          {t("copy-to-clipboard-success-message")}
        </span>
      )}
    </small>
  );
};

export default CopyToClipBoard;
