import { USER_ROLES } from "@ra/utils-v2/dist/crm-fe";
import JwtDecode from "jwt-decode";

import { setCookie, deleteCookie, getCookie } from "./index";

export const AUTH_COOKIE_NAME = `accessToken`;

// This is the short lived token that is set from TraderPortal on the main domain
export const PAYMENTS_GATEWAY_TOKEN = "PG-Token";

export const getAuthToken = (): string | undefined => {
  return getCookie(PAYMENTS_GATEWAY_TOKEN) || getCookie(AUTH_COOKIE_NAME);
};

export const setAuthToken = (value: string): void => {
  setCookie(AUTH_COOKIE_NAME, value, {
    ...(import.meta.env.PROD && { sameSite: "Strict" }),
  });
};

export const deleteAuthToken = (): void => {
  deleteCookie(AUTH_COOKIE_NAME);
};

export interface ITokenInfo {
  roles: USER_ROLES[];
  level: string;
}
export const decodeAuthToken = (authToken: string): ITokenInfo => {
  return JwtDecode(authToken);
};
