import { ActionType, useAuthContext } from "context/Auth";
import { useBrand } from "context/Brand";
import { useEffect } from "react";
import { deleteAuthToken } from "utils";

export const useLogout = () => {
  const { dispatch } = useAuthContext();
  const { configs } = useBrand();

  return (): void => {
    deleteAuthToken();
    dispatch({ type: ActionType.SET_AUTH, payload: { isAuth: false } });
    const appURL = configs?.appURL;

    if (appURL) {
      window.location.href = appURL;
    }
  };
};

export const useRBACLogoutHandler = (): void => {
  const logout = useLogout();

  useEffect(() => {
    window.addEventListener("logout", logout);

    return (): void => {
      window.removeEventListener("logout", logout);
    };
  }, [logout]);
};
