import React from "react";
import { Trans } from "react-i18next";

const InstaCoinsEmailMessage = () => {
  return (
    <div className="h4">
      <Trans i18nKey="instacoins-message" components={{ br: <br /> }} />
    </div>
  );
};

export default InstaCoinsEmailMessage;
