import React, { useState, useEffect, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { callNetellerDeposit, callNetellerDepositFinish } from "../../API/API";
import { getError } from "../../utils/index";
import Input from "../../Components/Forms/Input";
import SubmitBtn from "../../Components/Forms/SubmitBtn";
import { useFormState } from "react-use-form-state";
import { useBrand } from "context/Brand";

export interface IProps {
  id: string;
  status: string;
}

const NetellerDepositForm = (props: IProps) => {
  const { t } = useTranslation();
  const { configs } = useBrand();
  const [error, setError] = useState({ message: "", retry: false });
  const { id } = props;
  const [formState, { email }] = useFormState();
  const [status, setStatus] = useState("");

  useEffect(() => {
    const callApi = async () => {
      try {
        await callNetellerDepositFinish(id);
        window.location.href = `${configs?.appURL}/deposit`;
      } catch (err) {
        setStatus("error");
        setError(getError(err));
      }
    };

    if (props.status === "VERIFICATION") {
      callApi();
    }
  }, [props.status, id, configs]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const {
      values: { customerEmail },
    } = formState;

    try {
      const {
        data: { url },
      } = await callNetellerDeposit(id, customerEmail);
      setStatus("success");
      setTimeout(() => {
        window.location.href = url;
      }, 1500);
    } catch (err) {
      setError(getError(err));
      setStatus("error");
    }
  };

  if (error.message) {
    return (
      <div>
        <Helmet>
          <title className="text-capitalize">
            {t("neteller-deposit-error-page-meta-title", {
              brandName: configs?.websiteName,
            })}
          </title>
        </Helmet>

        <div className="alert alert-danger" role="alert">
          {error.message}
        </div>
      </div>
    );
  }

  if (props.status === "PENDING") {
    return (
      <form onSubmit={handleSubmit}>
        <Helmet>
          <title>
            {t("neteller-deposit-page-meta-title", {
              brandName: configs?.websiteName,
            })}
          </title>
        </Helmet>

        <Input
          className="form-control"
          placeholder={t("email-input-placeholder")}
          icon="fas fa-user"
          label={t("email-input-label", { brand: "Neteller" })}
          required
          {...email("customerEmail")}
        />

        <SubmitBtn status={status} />
      </form>
    );
  }

  return (
    <div>
      <Helmet>
        <title className="text-capitalize">
          {t("neteller-deposit-page-meta-title", {
            brandName: configs?.websiteName,
          })}
        </title>
      </Helmet>

      <h4 className="text-muted mb-0">
        <i className="fas fa-spinner fa-spin" /> {t("loading-text")}
      </h4>
    </div>
  );
};

export default NetellerDepositForm;
