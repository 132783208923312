import Cookies, { CookieAttributes } from "js-cookie";

export const setCookie = (
  name: string,
  value: string,
  options: CookieAttributes = {}
): void => {
  const isSecure = import.meta.env.PROD;
  Cookies.set(name, value, {
    secure: isSecure,
    expires: 1,
    ...options,
  });
};

export const getCookie = (name: string): string | undefined => {
  return Cookies.get(name);
};

export const deleteCookie = (name: string): void => {
  return Cookies.remove(name);
};
