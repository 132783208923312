import React, { FC } from "react";
import styled from "styled-components";

const Container = styled.div`
  font-family: ${(props) => props.theme.font};
  font-size: 14px;
  border: 1px solid #d79528;
  position: relative;
  border-radius: 5px;
`;

const Icon = styled.i`
  margin-top: 3px;
  width: 50px;
  font-size: 18px;
  color: #d79528;
`;

interface IBubbleAlertProps {
  icon: string;
}

const BubbleAlert: FC<IBubbleAlertProps> = ({ children, icon }) => {
  return (
    <Container className="p-2 mt-2 mb-2 text-left">
      <div className="d-flex">
        <div style={{ width: "50px" }}>
          {icon && <Icon className={`${icon} mr-2`} />}
        </div>
        <div className="w-95">{children}</div>
      </div>
    </Container>
  );
};

export default BubbleAlert;
