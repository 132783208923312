import React, { useState, FC, FormEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useFormState } from "react-use-form-state";

import { Currencies } from "@ra/utils-v2/dist/gateway";
import { IDragAndPayWithdrawFormData } from "API/types";
import { callDragAndPayWithdraw } from "API/API";
import Input, { Label } from "../../Components/Forms/Input";
import SubmitBtn from "../../Components/Forms/SubmitBtn";
import { getError } from "../../utils";
import { useBrand } from "context/Brand";

interface IPaymentChannel {
  procId: string;
  shortName: string;
  logo: string;
  billerId: string;
  status: string;
  merchantFee: number;
  userFee: number;
  isInstapay: boolean;
  maxAmount: number;
  apiKey: string;
  ipWhitelist: string;
  isCashPickup: boolean;
}

interface IDragAndPayWithdrawProps {
  /** Id of transaction */
  id: string;
  /** Currency of transaction */
  currency: Currencies;
  /** Amount of transaction */
  amount: number;
  /** Payment channels */
  paymentChannels: IPaymentChannel[];
}

const DragAndPayWithdraw: FC<IDragAndPayWithdrawProps> = ({
  amount,
  currency,
  id,
  paymentChannels = [],
}) => {
  const { t } = useTranslation();
  const { configs } = useBrand();
  const [formState, { select, text }] =
    useFormState<IDragAndPayWithdrawFormData>({
      receiver: "",
      paymentChannel: "",
    });
  const [status, setStatus] = useState("");
  const [error, setError] = useState({ retry: false, message: "" });
  const { receiver, paymentChannel } = formState.values;
  const isLoading = status === "loading";

  const resetError = () => {
    setError({ retry: false, message: "" });
    setStatus("");
  };

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();
    resetError();

    const data = {
      receiver,
      paymentChannel,
    };

    try {
      setStatus("loading");
      const res = await callDragAndPayWithdraw(id, data);
      setStatus("success");
      // redirect
      if (import.meta.env.PROD) {
        // wanna see success message before redirect, so wait a sec
        setTimeout(() => {
          window.location.href =
            res.data.redirect || `${configs?.appURL}/withdraw`;
        }, 1000);
      }
    } catch (err) {
      setError(getError(err));
      setStatus("error");

      if (getError(err).retry) {
        setTimeout(() => {
          setStatus("");
        }, 2000);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Helmet>
        <title>
          {t("drag-and-pay-withdraw-page-meta-title", {
            brandName: configs?.websiteName,
          })}
        </title>
      </Helmet>

      {error.message && (
        <div className="alert alert-danger mb-3" role="alert">
          {error.message}
        </div>
      )}

      <div>
        <Label htmlFor="paymentChannel">{t("select-payment-channel")}</Label>
        <select
          className="form-control"
          disabled={isLoading}
          required
          {...select({
            name: "paymentChannel",
            onChange: (v) => {
              resetError();
              return v;
            },
          })}
        >
          <option value="">{t("select")}</option>
          {paymentChannels.map(({ procId, shortName }) => (
            <option key={procId} value={procId}>
              {shortName}
            </option>
          ))}
        </select>
      </div>

      <div className="mt-3">
        <Input
          {...text("receiver")}
          className="form-control"
          icon="fas fa-user"
          label={t("payment-channel-additional-info-label")}
          placeholder={t("payment-channel-additional-info-placeholder")}
          required
        />
      </div>

      <SubmitBtn
        status={status}
        text={t("withdraw-submit", { amount, currency: Currencies[currency] })}
      />
    </form>
  );
};

export default DragAndPayWithdraw;
