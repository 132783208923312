import React, { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

const CryptoNetworkImageWrapper = styled.div`
  img {
    width: 24px;
    height: 24px;
  }
`;

interface ICryptoNetwork {
  name: string;
  image: string;
}

interface ICryptoNetworkWarningMessageProps {
  cryptoNetwork: ICryptoNetwork;
}

const CryptoNetworkWarningMessage: FC<ICryptoNetworkWarningMessageProps> = ({
  cryptoNetwork,
}) => {
  const { t } = useTranslation();
  const { image, name } = cryptoNetwork;

  return (
    <div className="mt-4">
      <CryptoNetworkImageWrapper className="font-weight-bold mt-8">
        <Trans
          i18nKey="deposit-info-network-warning"
          values={{ cryptoNetwork: name }}
          components={{
            img: (
              <img
                src={image}
                alt={t("deposit-info-network-warning-logo-alt", {
                  cryptoNetwork: name,
                })}
              />
            ),
          }}
        />
      </CryptoNetworkImageWrapper>
      <div className="mt-2">
        <p>{t("deposit-info-network-warning-2")}</p>
      </div>
    </div>
  );
};

export default CryptoNetworkWarningMessage;
