export const Theme = {
  color: "#000",
  colorSuccess: "#49a332",
  font: "'Poppins', sans-serif",
  input: {
    color: "#000",
    borderRadius: "16px",
    border: "1px solid #7D7D7D",
    focusBorder: "1px solid #428EFE",
  },
  btn: {
    bg: "#000",
    borderRadius: "8px",
    color: "white",
  },
};
