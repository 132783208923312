const mockData = {
  deposit: {
    mode: "deposit",
    type: "00",
    amount: 50,
    currency: 4,
    redirect: "https://c1-b1-crm-user.crmdevz.com/dashboard/deposits",
    status: "INITIALIZED",
    message: null,
    meta: {
      cryptoKind: "BTC",
      crmCallbacks: [
        {
          url: "https://api-c1-b1-crm.crmdevz.com/payment-confirm",
          send: {
            mode: "deposit",
            notes:
              "be:gateway-backend-service-waiting-for-user-to-send-bitcoins",
            action: "draft",
            amount: 50,
            crypto: {
              amount: 0.00107613,
              address: "2NDBJfkocCMrfXG8dBLBNQ3C4hRpGtfjkPn",
            },
            secretId: "694f5f12-6ed3-4852-b5a6-e3dc100e0709",
          },
        },
      ],
      crmReference: "65c5e8c0218bb8fc39d85871",
      zeroConfirmation: true,
      currencyTranslation: { amount: 50, currency: "USD", translated: false },
      cryptochillCreatePayload: {
        kind: "BTC",
        nonce: 1707469001222,
        notes:
          "CRYPTO_BTC Invoice created by CRM C1B1 with txId: af52733d-5958-42b1-922f-356e719d0e49",
        amount: 50,
        request: "/v1/invoices/",
        currency: "USD",
        profile_id: "c4549644-af74-49b6-9b3d-8dee770fca2c",
        passthrough:
          '{"type":"CRYPTO_BTC","txId":"af52733d-5958-42b1-922f-356e719d0e49","_id":"65c5e8c0218bb8fc39d85871","app_name":"CRM","external_user_id":"63f49c1200e20f00123f7ff1","external_user_ref":"ruthie6@ethereal.email","external_record_id":"af52733d-5958-42b1-922f-356e719d0e49","external_record_ref":"transactions","external_brand_id":"615da9d7a4d6be8a8c010015","external_brand_ref":"CRM C1B1","notes":"CRYPTO_BTC Invoice created for an amount of: 50 USD","callback_url":""}',
        min_confirmations: 3,
        zero_conf_enabled: true,
        exchange_rate_limit: {
          pair: "BTC:USD",
          exchange_rate: 46486.82556390495,
          allowed_difference: 0.05,
        },
      },
      cryptochillCreateResponse: {
        id: "008d7308-db82-4b1d-9d3f-4a80d578651c",
        kind: "BTC_123",
        notes:
          "CRYPTO_BTC Invoice created by CRM C1B1 with txId: af52733d-5958-42b1-922f-356e719d0e49",
        amount: {
          invoiced: { amount: "0.00107613", currency: "BTC__" },
          requested: { amount: "50.00", currency: "USD" },
        },
        status: "new",
        address: "2NDBJfkocCMrfXG8dBLBNQ3C4hRpGtfjkPn",
        network: "testnet",
        lightning: null,
        created_at: "2024-02-09T08:56:41.648724+00:00",
        custom_fee: null,
        expires_at: "2024-02-09T09:11:41.648042+00:00",
        profile_id: "c4549644-af74-49b6-9b3d-8dee770fca2c",
        passthrough:
          '{"type":"CRYPTO_BTC","txId":"af52733d-5958-42b1-922f-356e719d0e49","_id":"65c5e8c0218bb8fc39d85871","app_name":"CRM","external_user_id":"63f49c1200e20f00123f7ff1","external_user_ref":"ruthie6@ethereal.email","external_record_id":"af52733d-5958-42b1-922f-356e719d0e49","external_record_ref":"transactions","external_brand_id":"615da9d7a4d6be8a8c010015","external_brand_ref":"CRM C1B1","notes":"CRYPTO_BTC Invoice created for an amount of: 50 USD","callback_url":""}',
        transactions: [],
        min_confirmations: 3,
        zero_conf_enabled: true,
      },
    },
    cryptoCurrencies: ["BTC"],
  },
  withdraw: {
    id: "fc4f34a6-feb6-499a-80a9-b2abf5d5f9b5",
    mode: "withdraw",
    redirect: "https://dev-login.ospreyfx.com/withdraw",
    meta: {
      cryptoKind: "BTC",
      crmReference: "6151a70eb2f04a0012223f5d",
    },
    type: "00",
    amount: 50,
    currency: 3,
  },
  postDeposit: {
    message: "Success",
  },
  invoice: {
    id: "123",
    kind: "BTC_BECH32",
    created_at: Date.now(), //"2019-10-01T08:47:59.723517+00:00",
    profile_id: "dc1efc54-4047-42ca-b6d9-576008ce60ae",
    address: "tb1qls88e4kqjpp85d9550vvme8h0j0l3dfk5u528q0m5pc4jedp37wqe7rxkc",
    lightning: null,
    status: "incomplete",
    expires_at: Date.now() + 1000 * 60 * 15, //"2019-10-01T09:02:59.723255+00:00",
    network: "testnet",
    amount: {
      requested: {
        amount: "6.00",
        currency: "USD",
      },
      invoiced: {
        amount: "1,230.00071177",
        currency: "BTC",
      },
      paid: {
        amount: "230",
        currency: "BTC",
      },
    },
    custom_fee: null,
    min_confirmations: null,
    notes:
      "Invoice created by devLocalWebUI with id: b4195eeb-d71e-49c4-b824-185fe210e670",
    passthrough: "b4195eeb-d71e-49c4-b824-185fe210e670",
    transactions: [],
  },
  invoicePending: {
    id: "123",
    kind: "BTC",
    created_at: Date.now(), //"2019-10-01T08:47:59.723517+00:00",
    profile_id: "dc1efc54-4047-42ca-b6d9-576008ce60ae",
    address: "tb1qls88e4kqjpp85d9550vvme8h0j0l3dfk5u528q0m5pc4jedp37wqe7rxkc",
    lightning: null,
    status: "pending",
    expires_at: Date.now() + 1000 * 60 * 15, //"2019-10-01T09:02:59.723255+00:00",
    network: "testnet",
    amount: {
      requested: {
        amount: "6.00",
        currency: "USD",
      },
      invoiced: {
        amount: "0.00071177",
        currency: "BTC",
      },
      paid: {
        amount: "0.00001177",
        currency: "BTC",
      },
    },
    custom_fee: null,
    min_confirmations: null,
    notes:
      "Invoice created by devLocalWebUI with id: b4195eeb-d71e-49c4-b824-185fe210e670",
    passthrough: "b4195eeb-d71e-49c4-b824-185fe210e670",
    transactions: [],
  },
  settings: {
    total: 1,
    data: [
      {
        name: "brand",
        data: {
          name: "Test Brand",
        },
      },
      {
        name: "Crypto Network Warning",
        data: {
          enabled: true,
        },
      },
      {
        name: "RD_2863-payments-service-authentication",
        data: {
          enabled: false,
        },
      },
      {
        name: "paymentMethodOptionsKindToNameMap",
        data: {
          "8079": "Dragon Pay via PHP",
          "BTC": "Bitcoin (BTC)!",
          "BTC_LIGHTNING": "Bitcoin Lightning",
          "LTC": "Litecoin (LTC)",
          "ETH": "Ethereum (ETH)",
          "ETH_USDT": "Tether",
          "XRP": "Ripple (XRP)",
          "DOGE": "Dogecoin (DOGE)",
        },
      },
    ],
  },
  feSettings: {
    theme: {
      color: "#ad2324",
      colorSuccess: "#49a332",
      font: "'Poppins', sans-serif",
      input: {
        color: "black",
        borderRadius: "27px",
        border: "1px solid grey",
        focusBorder: "1px solid #f08b59",
      },
      btn: {
        bg: "#ad2324",
        borderRadius: "0px",
        color: "white",
      },
    },
    configs: {
      apiURL: "https://c1api.crmpayments.com",
      fontURL:
        "https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap",
      websiteName: "TradOwise",
      appURL: "https://login.ospreyfx.com ",
      enableSentry: false,
      sentryEnv: "staging",
      enableLoggly: false,
      logglyToken: "4b834169-ccd7-4dc4-bbd5-5f4c2a6df23c",
      zeplyAffId: "1",
    },
  },
};

export default mockData;
