import { useBrand } from "context/Brand";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Back = styled.a`
  color: ${(props) => props.theme.goBackColor || "grey"};
  opacity: 0.6;
  transition: all 0.2s linear;

  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.color};
    opacity: 1;
  }
`;

interface IGoBack {
  mode?: string;
}

const GoBack = (props: IGoBack) => {
  const { t } = useTranslation();
  const { configs } = useBrand();
  const { mode } = props;
  let url = configs?.appURL;
  if (mode) {
    url += `/${mode}`;
  }

  return (
    <div className="text-center">
      <Back href={url}>
        <i className="fas fa-long-arrow-alt-left" />{" "}
        {t("go-back", { brandName: configs?.websiteName })}
      </Back>
    </div>
  );
};

export default GoBack;
