import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import SubmitBtn from "../../Components/Forms/SubmitBtn";
import { callCryptoBtcPostDeposit } from "../../API/API";
import { getError, getPaymentOptionsKindToNameMap } from "../../utils/index";
import { usePublicSettingsQuery } from "API/hooks";

interface IProps {
  id: string;
  cryptoCurrencies: any;
  cb: () => void;
}

const CryptoBtcCurrencyForm = ({ cryptoCurrencies, id, cb }: IProps) => {
  const { t } = useTranslation();
  const initialCurrency = cryptoCurrencies[0] || "";
  const [currency, setCurrency] = useState(initialCurrency);
  const [status, setStatus] = useState<{
    status: "" | "loading" | "error" | "success";
    message: string;
  }>({ status: "", message: "" });

  const { data: appSettings } = usePublicSettingsQuery();

  const translalateSymbolToKind = useCallback(
    (kind: string) => {
      const symbolToKindMapping = getPaymentOptionsKindToNameMap(appSettings);
      return symbolToKindMapping?.[kind] ?? kind;
    },
    [appSettings]
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await handlePostDeposit();
  };

  const handlePostDeposit = useCallback(() => {
    try {
      setStatus({ status: "loading", message: "" });
      callCryptoBtcPostDeposit(id, { kind: currency });
      setStatus({ status: "success", message: "" });
      setTimeout(() => cb(), 1500);
    } catch (err) {
      const getErr = getError(err);
      setStatus({ status: "error", message: getErr.message });
      if (getErr.retry) {
        setTimeout(() => {
          setStatus({ status: "", message: "" });
        }, 1000);
      }
    }
  }, [cb, id, currency]);

  useEffect(() => {
    if (cryptoCurrencies.length === 1) {
      handlePostDeposit();
    }
  }, [cryptoCurrencies, handlePostDeposit]);

  if (cryptoCurrencies.length === 1) {
    return (
      <h4 className="text-muted mb-0">
        <i className="fas fa-spinner fa-spin" /> {t("loading-text")}
      </h4>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <h3 className="mb-3">{t("crypto-deposit-title")}</h3>

      {status.status === "error" && (
        <div className="alert alert-danger mb-3">{status.message}</div>
      )}

      <label htmlFor="selectCurrency">
        {t("crypto-deposit-currency-input-label")}
      </label>
      <select
        onChange={(e) => setCurrency(e.target.value)}
        className="form-control mb-3"
        name="selectCurrency"
        id="selectCurrency"
        required
        value={currency}
      >
        <option value="" disabled>
          {t("crypto-deposit-currency-input-label")}
        </option>
        {cryptoCurrencies.map((c: string) => (
          <option key={c} value={c}>
            {translalateSymbolToKind(c)} - {c}
          </option>
        ))}
      </select>
      <SubmitBtn type="submit" status={status.status} />
    </form>
  );
};

export default CryptoBtcCurrencyForm;
