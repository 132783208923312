import { CRYPTO_NETWORKS_MAP } from "@ra/utils-v2/dist/crm-fe";
import BSCLogo from "../../assets/crypto-networks/bsc.svg?url";
import TronLogo from "../../assets/crypto-networks/tron.svg?url";
import ETHLogo from "assets/crypto-networks/eth.svg?url";

export interface ICryptoNetwork {
  name: string;
  image: string;
}

type TNetworkData = {
  [key: string]: ICryptoNetwork;
};

export const networkData: TNetworkData = {
  [CRYPTO_NETWORKS_MAP.TRX]: {
    name: CRYPTO_NETWORKS_MAP.TRX,
    image: TronLogo,
  },
  [CRYPTO_NETWORKS_MAP.BSC]: {
    name: CRYPTO_NETWORKS_MAP.BSC,
    image: BSCLogo,
  },
  [CRYPTO_NETWORKS_MAP.ETH]: {
    name: CRYPTO_NETWORKS_MAP.ETH,
    image: ETHLogo,
  },
};

// function below handles the logic for deposit & withdraw network to reflect cryptochill returned kinds
export const getNetworkMapped = (cryptoKind: string) => {
  const network =
    cryptoKind.split("-")?.[1] || cryptoKind.split("_")?.[0] || "";

  const networkMapped =
    CRYPTO_NETWORKS_MAP?.[network as keyof typeof CRYPTO_NETWORKS_MAP];

  return networkMapped;
};
