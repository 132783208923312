import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const DepositFormHidden = ({ hiddenData, submit }: any) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (submit) {
      const form = document.getElementById("PostForm") as HTMLFormElement;
      form.submit();
    }
  }, [submit]);

  const {
    account_id = "",
    account_password = "",
    account_sha = "",
    action_type = "",
    account_gateway = "",
    merchant_payment_id = "",
    cust_email = "",
    cust_billing_last_name = "",
    cust_billing_first_name = "",
    cust_billing_address = "",
    cust_billing_city = "",
    cust_billing_zipcode = "",
    cust_billing_state = "",
    cust_billing_country = "",
    cust_billing_phone = "",
    transac_products_name = "",
    transac_amount = "",
    transac_currency_code = "",
    customer_ip = "",
    merchant_url_return = "",
  } = hiddenData;

  return (
    <form
      id="PostForm"
      name="PostForm"
      action={import.meta.env.REACT_APP_EUPAYMENTZ_URL}
      method="POST"
      style={{ display: "none" }}
    >
      <input readOnly type="text" name="account_id" value={account_id} />
      <input
        readOnly
        type="text"
        name="account_password"
        value={account_password}
      />
      <input readOnly type="text" name="account_sha" value={account_sha} />
      <input readOnly type="text" name="action_type" value={action_type} />
      <input
        readOnly
        type="text"
        name="account_gateway"
        value={account_gateway}
      />
      <input
        readOnly
        type="text"
        name="merchant_payment_id"
        value={merchant_payment_id}
      />
      <input readOnly type="text" name="cust_email" value={cust_email} />
      <input
        readOnly
        type="text"
        name="cust_billing_last_name"
        value={cust_billing_last_name}
      />
      <input
        readOnly
        type="text"
        name="cust_billing_first_name"
        value={cust_billing_first_name}
      />
      <input
        readOnly
        type="text"
        name="cust_billing_address"
        value={cust_billing_address}
      />
      <input
        readOnly
        type="text"
        name="cust_billing_city"
        value={cust_billing_city}
      />
      <input
        readOnly
        type="text"
        name="cust_billing_zipcode"
        value={cust_billing_zipcode}
      />
      <input
        readOnly
        type="text"
        name="cust_billing_state"
        value={cust_billing_state}
      />
      <input
        readOnly
        type="text"
        name="cust_billing_country"
        value={cust_billing_country}
      />
      <input
        readOnly
        type="text"
        name="cust_billing_phone"
        value={cust_billing_phone}
      />
      <input
        readOnly
        type="text"
        name="transac_products_name"
        value={transac_products_name}
      />
      <input
        readOnly
        type="text"
        name="transac_amount"
        value={transac_amount}
      />
      <input
        readOnly
        type="text"
        name="transac_currency_code"
        value={transac_currency_code}
      />
      <input readOnly type="text" name="transac_cc_type" value="" />
      <input readOnly type="text" name="transac_cc_number" value="" />
      <input readOnly type="text" name="transac_cc_month" value="" />
      <input readOnly type="text" name="transac_cc_year" value="" />
      <input readOnly type="text" name="transac_cc_cvc" value="" />
      <input readOnly type="text" name="customer_ip" value={customer_ip} />
      <input
        readOnly
        type="text"
        name="merchant_url_return"
        value={merchant_url_return}
      />
      <input type="submit" value={t<string>("submit")} />
    </form>
  );
};

export default DepositFormHidden;
