import { useEffect, useState } from "react";
import {
  Currencies,
  PAYZIP_CARD_TYPE,
  PayzipDepositPayload,
} from "@ra/utils-v2/dist/gateway";
import { useBrand } from "context/Brand";

import { format } from "@buttercup/react-formatted-input";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { useTranslation } from "react-i18next";

import { callPayzipDeposit } from "../../API/API";
import CardWrap from "../../Components/CardWrap";
import ErrorBox from "../../Components/ErrorBox";
import Button from "../../Components/Forms/SubmitBtn";
import { getError } from "../../utils/index";

const cardNoFormat = [
  { char: /\d/, repeat: 4 },
  { exactly: " " },
  { char: /\d/, repeat: 4 },
  { exactly: " " },
  { char: /\d/, repeat: 4 },
  { exactly: " " },
  { char: /\d/, repeat: 4 },
];

const cvcFormat = [{ char: /\d/, repeat: 3 }];

const expFormat = [
  { char: /\d/, repeat: 2 },
  { exactly: "/" },
  { char: /\d/, repeat: 2 },
];

interface PayzipDepositProps {
  id: string;
  status: string;
  errMessage?: string;
  currency: number;
  amount: number;
}

interface SubmitStatus {
  message: string;
  status: "error" | "success" | "loading" | "";
}

const payzipAllowedCardTypes = Object.values(PAYZIP_CARD_TYPE)
  .filter((x) => typeof x === "string")
  .map((cardType) => (cardType as string).toLowerCase());

const PayzipDeposit = ({
  status,
  amount,
  currency,
  id,
  errMessage,
}: PayzipDepositProps) => {
  const { t } = useTranslation();
  const { configs } = useBrand();

  useEffect(() => {
    if (status !== "PENDING") {
      setTimeout(() => {
        window.location.href = `${configs?.appURL}/deposit`;
      }, 4000);
    }
  }, [status, configs]);

  const [state, setState] = useState({
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    firstname: "",
    lastname: "",
    number: "",
    issuer: "",
    raw: {
      cvc: "",
      expiryM: "",
      expiryY: "",
      number: "",
    },
  });
  const [submitStatus, setSubmitStatus] = useState<SubmitStatus>({
    message: "",
    status: "",
  });

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const { expiryM, expiryY, number } = state.raw;
    const { name, cvc, issuer } = state;

    if (!payzipAllowedCardTypes.includes(issuer)) {
      setSubmitStatus({
        message: t("unsupported-card-issuer-error"),
        status: "error",
      });
      setTimeout(() => {
        setSubmitStatus({ message: "", status: "" });
      }, 5000);
      return;
    }

    const submitData: PayzipDepositPayload = {
      cardHolderName: name,
      cardType: issuer.toUpperCase() as keyof typeof PAYZIP_CARD_TYPE,
      cardNumber: number,
      expiryMonth: expiryM,
      expiryYear: expiryY,
      cardCvv: cvc,
    };

    try {
      setSubmitStatus({ status: "loading", message: "" });
      await callPayzipDeposit(id, submitData);
      setSubmitStatus({ status: "success", message: "" });
      setTimeout(() => {
        window.location.href = `${configs?.appURL}/deposit`;
      }, 4000);
    } catch (err) {
      const { message, retry } = getError(err);
      setSubmitStatus({ status: "error", message });

      if (retry) {
        setTimeout(() => {
          setSubmitStatus({ status: "", message: "" });
        }, 4000);
      }
    }
  };

  const submitText = () => {
    let text = t("pay-submit-button", {
      amount,
      currency: Currencies[currency],
    });
    return text;
  };

  const handleChange = (event: any) => {
    const { value, name } = event.target;

    if (name === "number") {
      const { formatted, raw } = format(value, cardNoFormat);
      setState({
        ...state,
        number: formatted,
        raw: {
          ...state.raw,
          number: raw,
        },
      });
    }

    if (name === "cvc") {
      const { formatted, raw } = format(value, cvcFormat);
      setState({
        ...state,
        cvc: formatted,
        raw: {
          ...state.raw,
          cvc: raw,
        },
      });
    }

    if (name === "expiry") {
      const { formatted } = format(value, expFormat);
      const splitDate = formatted.split("/");

      setState({
        ...state,
        expiry: formatted,
        raw: {
          ...state.raw,
          expiryM: splitDate[0],
          expiryY: splitDate[1],
        },
      });
    }

    if (name === "firstname") {
      setState({
        ...state,
        firstname: value,
        name: `${value} ${state.lastname}`,
      });
    }

    if (name === "lastname") {
      setState({
        ...state,
        name: `${state.firstname} ${value}`,
        lastname: value,
      });
    }
  };

  const handleFocus = (e: any) => {
    setState({ ...state, focus: e.target.name });
  };

  if (submitStatus.status === "success") {
    return (
      <CardWrap mode="deposit">
        <div className="text-center">
          <h2>
            <i className="fas fa-check mr-1" />
            {t("success")}
          </h2>
          <p>{t("payzip-transaction-sent")}</p>
          <p className="text-muted">
            <i className="fas fa-spinner fa-spin mr-1" />
            {t("redirecting-text")}
          </p>
        </div>
      </CardWrap>
    );
  }

  if (status === "PENDING") {
    return (
      <CardWrap mode="deposit">
        <form onSubmit={handleSubmit}>
          {submitStatus.status === "error" && (
            <div className="alert alert-danger" role="alert">
              {submitStatus.message}
            </div>
          )}

          <Cards
            cvc={state.cvc}
            expiry={state.expiry}
            // @ts-ignore
            focused={state.focus}
            name={state.name}
            number={state.number}
            callback={({ issuer }) => {
              setState({
                ...state,
                issuer,
              });
            }}
          />
          <div className="mt-3 mb-3">
            <div className="form-row">
              <div className="col">
                <input
                  className="form-control mb-2"
                  type="text"
                  name="firstname"
                  value={state.firstname}
                  onChange={handleChange}
                  placeholder={t("first-name-input-placeholder")}
                  onFocus={handleFocus}
                  required
                />
              </div>
              <div className="col">
                <input
                  className="form-control mb-2"
                  type="text"
                  name="lastname"
                  value={state.lastname}
                  onChange={handleChange}
                  placeholder={t("last-name-input-placeholder")}
                  onFocus={handleFocus}
                  required
                />
              </div>
            </div>
            <input
              className="form-control mb-2"
              type="text"
              name="number"
              value={state.number}
              onChange={handleChange}
              placeholder={t("card-number-input-placeholder")}
              onFocus={handleFocus}
              required
            />
            <div className="d-flex">
              <input
                className="form-control mr-1"
                type="text"
                name="expiry"
                value={state.expiry}
                onChange={handleChange}
                placeholder="mm/yy"
                onFocus={handleFocus}
                required
              />
              <input
                className="form-control ml-1"
                type="text"
                name="cvc"
                value={state.cvc}
                onChange={handleChange}
                placeholder="CVC"
                onFocus={handleFocus}
                required
              />
            </div>
          </div>

          <div>
            <Button
              text={submitText()}
              type="submit"
              status={submitStatus.status}
            />
          </div>
        </form>
      </CardWrap>
    );
  }

  return (
    <ErrorBox mode="deposit" title={t("error-box-title")} text={errMessage} />
  );
};

export default PayzipDeposit;
